import { useEffect, useState } from "react";
import iglesiasService from "../../services/iglesias";
import { 
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, 
  Grid, IconButton, Typography, CircularProgress 
} from "@mui/material";
import authService from "../../services/auth";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { theme } from "../../theme";
import { uploadImage } from "../../utils/images/Upload";

const IglesiaDetailPage = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false); // Estado para manejar el loader
  const navigate = useNavigate();
  const [idIgle, setIdIgle] = useState<string | null>(null);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const iglesiaId = authService.getIglesiaSelected()?.iglesia?.id;

    if (iglesiaId) {
      setIdIgle(iglesiaId);

      iglesiasService
        .getImageByIdIgle(iglesiaId)
        .then((imageUrl: string) => {
          setImagePreview(imageUrl);
        })
        .catch((error) => {
          console.error("Error al obtener la imagen:", error);
        });
    }
  }, []);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));
    setSelectedImage(file);
    setOpenModal(true);
  };

  const handleConfirmImage = async () => {
    if (!selectedImage) return;

    setIsUploading(true);

    try {
      const imageUrl = await uploadImage(selectedImage);
      setImageUrl(imageUrl);
      setOpenModal(false);
    } catch (error) {
      console.error("Upload failed", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancelImage = () => {
    setOpenModal(false);
  };

  return (
    <Grid container justifyContent={"center"} sx={{ height: "100%" }}>
      <Grid item xs={6} style={{ padding: "5%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
            borderRadius: "8px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            width: "100%",
            height: "10%"
          }}
        >
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              color: theme.font.primary,
              fontSize: "18px",
              fontWeight: 600,
              marginLeft: "15px",
            }}
          >
            Volver
          </Typography>
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            justifyContent: "center",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            width: "100%"
          }}
        >
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "15px 0",
                fontWeight: 500,
                color: "#3A8DBE",
              }}
            >
              Fondo del Panel
            </Typography>
            <img
              src={imagePreview || ""}
              style={{
                width: "100%",
                maxWidth: "350px",
                height: "350px",
                borderRadius: "5px",
                objectFit: "cover",
                paddingBottom: "5px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                margin: "10px 0",
                fontWeight: 500,
                color: "#3A8DBE",
              }}
            >
              Aspect ratio recomendado: 2:1
            </Typography>
          </div>

          <div style={{ marginTop: "20px" }}>
            <input
              style={{
                all: "unset",
                cursor: "pointer",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor: "#3A8DBE",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "40px",
                marginBottom: "20px",
              }}
              type="file"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </Grid>

      {/* Confirmation Modal */}
      <Dialog open={openModal} onClose={handleCancelImage}>
        <DialogTitle>{"Confirmación"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Desea usar esta imagen como el fondo del panel en esta iglesia?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelImage} color="secondary" disabled={isUploading}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmImage} color="primary" disabled={isUploading}>
            {isUploading ? <CircularProgress size={24} sx={{ color: "#3A8DBE" }} /> : "Confirmar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default IglesiaDetailPage;
