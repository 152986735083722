import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class UsersService extends GenericService {
  constructor() {
    super();
  }

  async deleteUser(id: string): Promise<void> {
    try {
      await this.delete(`/usuarios/${id}`);
    } catch (error) {
      console.error("Error al eliminar el usuario", error);
      throw error;
    }
  }

   async addRoleDisabledToUser(id: number): Promise<void> {
    try {
      await this.post(`/usuarios/addRoleDisabledToUser`, Number(id));
    } catch (error) {
      console.error("Error al agregar rol disabled al usuario", error);
      throw error;
    }
  }

  async updateRolesUser(formData: any): Promise<void> {
    try {
      await this.put(`/usuarios/updateRolesOfUser`, formData);
    } catch (error) {
      console.error("Error al actualizar los roles del usuario", error);
      throw error;
    }
  }

  async getSystemRolsOfUser(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.get(
        `/usuarios/getSystemRolsOfUser/${id}`,
      );
      return response;
    } catch (error) {
      console.error("Error al obtener los roles del usuario", error);
      throw error;
    }
  }
}

const usersService = new UsersService();
export default usersService;
