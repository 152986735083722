import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import {
  AltaParticipanteTextos,
  CreateActividadParroquialEnum,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DOCUMENT,
  Errors,
  FormTextSend,
  Locaciones,
} from "../../../enums/components/componentsEnum";
import {
  inputLabelStyles,
  PeopleActividadParroquialStyles,
} from "./PeopleActividadParroquialStyles";
import { LoadState } from "../../../enums/LoadStateEnum";
import peopleServie from "../../../services/people";
import {
  assignResponseToParticipante,
  formatParticipanteData,
  initialParticipanteState,
  optionsYears,
  sortByProperty,
  sortByPropertyAndNameForSchool,
  updateActividadParroquialFlags,
} from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";
import { useQuery } from "react-query";
import { locacionesService } from "../../../services/locaciones";
import { stylesActividadParroquial } from "../createActividadParroquial/CreateActividadParroquialStyles";
import {
  ActividadParroquialNombre,
  AllKeys,
  BirthPlaces,
  Group,
  IglesiaAddress,
  IglesiaDirection,
  NestedKeys,
  Participante,
  SchoolType,
  TopLevelKeys,
} from "../InterfacesActividadParroquial";
import LinksAndHeader from "./LinksAndHeader";
import colegioService from "../../../services/colegios/colegioService";
import actividadParroquialService from "../../../services/actividadParroquial/ActividadParroquialService";
import turnoExpecificationService from "../../../services/turnosExpecification";
import grupoParroquialService from "../../../services/grupoParroquial/GrupoParroquialService";
import participanteActividadParroquialService from "../../../services/ParticipanteActividadParroquial/ParticipanteActividadParroquialService";
import { queryClient } from "../../../App";
import { addNotification } from "../../../utils/notifications";
import ActividadParroquialInputs from "./ActividadParroquialInputs";
import CargaEscuela from "./CargaEscuela";
import DatosNinio from "./DatosNinio";
import DatosIglesia from "./DatosIglesia";
import DatosPadre from "./DatosPadre";
import DatosMadre from "./DatosMadre";
import { set } from "date-fns";

const CreateParticipante: React.FC = () => {
  const navigate = useNavigate();
  const [forceRender, setForceRender] = useState<Date>();
  const [isFetch, setIsFetch] = useState(false);
  const loadState = useRef(LoadState.DEFAULT);
  const [iglesias, setIglesias] = useState<any[]>([]);
  const [iglesiaProvinces, setIglesiaProvinces] = useState<any[]>([]);
  const [iglesiaParts, setIglesiaParts] = useState<any[]>([]);
  const [activityId, setActivityId] = useState();
  const [birthNinioPlaces, setBirthNinioPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  const [birthFatherPlaces, setBirthFatherPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  const [birthMotherPlaces, setBirthMotherPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  const [addressIglesia, setAddressIglesia] = useState<IglesiaAddress>({
    country: null,
    province: null,
    part: null,
    locality: null,
  });
  let [participante, setParticipante] = useState<Participante>(
    initialParticipanteState(),
  );
  const [iglesiaDirection, setIglesiaDirection] = useState<IglesiaDirection>({
    iglesia: null,
    part: null,
    province: null,
    country: null,
  });
  const [colegios, setColegios] = useState<SchoolType[]>([]);
  const {
    data: countries,
    isLoading: locacionesDataIsLoading,
    error: locacionesDataError,
  } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
    locacionesService.getCountries(),
  );
  const [actividadParroquialNombres, setActividadParroquialNombre] = useState<
    ActividadParroquialNombre[]
  >([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [encuentro, setEncuentro] = useState([]);
  const [fetchedSchool, setFetchedSchool] = useState<SchoolType | null>(null);
  const location = useLocation();
  const nameId = location.state?.nameId;
  const groupId = location.state?.groupId;
  const pathParts = location.pathname.split("/");
  const [disabledNinio, setDisabledNinio] = useState(false);
  const [disabledFather, setDisabledFather] = useState(false);
  const [disabledMother, setDisabledMother] = useState(false);
  const [disabledChurch, setDisabledChurch] = useState(false);
  const [disabledSchool, setDisabledSchool] = useState(false);
  const [disabledTurno, setDisabledTurno] = useState(false);
  const isEditRoute = pathParts.includes("edit-participantes");
  const [isActivityParroquialCatequesis, setIsActivityParroquialCatequesis] =
    useState(false);
  const [
    isActivityParroquialMovimientoMisionero,
    setIsActivityParroquialMovimientoMisionero,
  ] = useState(false);
  const [schoolLevel, setSchoolLevel] = useState<number>(0);
  const [levels, setLevels] = useState<any[]>([]);

  useEffect(() => {
    if (isEditRoute) {
      const document = pathParts[pathParts.length - 2];
      const documentType = pathParts[pathParts.length - 1];
      if (document && documentType) {
        handleFetchPerson(document, documentType, "ninio");
        handleItemChange(
          location.state?.actividadParroquialNameId,
          "nameId",
          location.state?.nameOfActivity,
        );
        setDisabledTurno(true);
        participanteActividadParroquialService
          .getParticipantesById(location.state?.id)
          .then((response: any) => {
            onChangeAddressIglesia(
              "nationality",
              response.colegio?.localidad.part?.province.country,
            );
            onChangeAddressIglesia(
              "birthProvince",
              response.colegio?.localidad.part?.province,
            );
            onChangeAddressIglesia(
              "birthPart",
              response.colegio?.localidad.part,
            );
            onChangeAddressIglesia("birthLocality", response.colegio.localidad);
            setFetchedSchool(response.colegio);
            setParticipante((prev) => ({
              ...prev,
              school: {
                ...prev.school,
                yearStudying: response.yearStudying,
                religious: response.religious,
                name: response.colegio.nombre,
                schoolId: response.colegio.id,
                cue: response.colegio.cue,
              },
            }));
            setDisabledSchool(true);
          })
          .catch((error: any) => {
            console.error("Error al traer participante", error);
          });
      }
    }
  }, [location]);

  useEffect(() => {
    if (participante.activity.nameId && isEditRoute) {
      handleItemChange(location.state?.groupParroquialId, "groupId");
    }
  }, [participante.activity.nameId]);

  useEffect(() => {
    if (nameId) {
      handleItemChange(nameId, "nameId");
    }
  }, []);

  useEffect(() => {
    if (groupId) {
      handleItemChange(groupId, "groupId");
    }
  }, [participante.activity.nameId]);

  useEffect(() => {
    actividadParroquialService
      .getActividadNombres()
      .then((response: any) => {
        const sortedResponse = sortByProperty(response, "name");
        setActividadParroquialNombre(sortedResponse);
      })
      .catch((error: any) => {
        console.error(error);
      });
    colegioService
      .findSchoolLevels()
      .then((response: any) => {
        const sortedResponse = response.sort((a: any, b: any) =>
          a.description.localeCompare(b.description),
        );
        setLevels(sortedResponse);
      })
      .catch((error: any) => {
        console.error("Error al traer niveles escolares", error);
      });
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const resetStateValidator = (
    state: LoadState = LoadState.DEFAULT,
    isFetch = false,
  ) => {
    setIsFetch(isFetch);
    loadState.current = state;
    setForceRender(new Date());
  };

  const handleFetchPerson = async (
    dni: string,
    documentType: string,
    typePeople: any,
  ) => {
    dni = dni ?? "";
    documentType = documentType ?? "";
    if (!!dni && dni.length > 0 && documentType.length > 0) {
      peopleServie
        .findPeople(dni, documentType)
        .then((response: any) => {
          if (!!response) {
            let updatedParticipante: Participante;
            let bautismoSacramento: any;
            switch (typePeople) {
              case "ninio":
                bautismoSacramento = response.sacramentos?.find(
                  (sacramento: any) =>
                    sacramento.sacramento.type === "Bautismo",
                );
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                  bautismoSacramento,
                );
                let updatedNinio = updatedParticipante?.ninio;
                if (bautismoSacramento) {
                  updatedNinio = {
                    ...updatedParticipante?.ninio,
                    dateSacramento: new Date(
                      bautismoSacramento?.sacramento?.startDate,
                    )
                      .toISOString()
                      .split("T")[0],
                  };
                  setDisabledChurch(true);
                }
                setBirthNinioPlaces({
                  nationality:
                    response?.locality?.part?.province?.country ||
                    response?.nationality,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });
                setBirthMotherPlaces({
                  nationality:
                    response?.mother?.locality?.part?.province?.country ||
                    response?.mother?.nationality,
                  birthProvince: response?.mother?.locality?.part?.province,
                  birthPart: response?.mother?.locality?.part,
                  birthLocality: response?.mother?.locality,
                });
                setBirthFatherPlaces({
                  nationality:
                    response?.father?.locality?.part?.province?.country ||
                    response?.father?.nationality,
                  birthProvince: response?.father?.locality?.part?.province,
                  birthPart: response?.father?.locality?.part,
                  birthLocality: response?.father?.locality,
                });

                setIglesiaDirection({
                  iglesia: bautismoSacramento?.sacramento?.iglesia,
                  part: bautismoSacramento?.sacramento?.iglesia?.locality?.part,
                  province:
                    bautismoSacramento?.sacramento?.iglesia?.locality?.part
                      ?.province,
                  country:
                    bautismoSacramento?.sacramento?.iglesia?.locality?.part
                      ?.province?.country,
                });

                setParticipante((prev) => ({
                  ...prev,
                  iglesia: {
                    ...prev.iglesia,
                    book: bautismoSacramento?.sacramento?.book,
                    folio: bautismoSacramento?.sacramento?.folio,
                  },
                  ninio: updatedNinio,
                  father: updatedParticipante?.father,
                  mother: updatedParticipante?.mother,
                }));
                setDisabledNinio(true);
                updatedParticipante.father.document !== "" &&
                  setDisabledFather(true);
                updatedParticipante.mother.document !== "" &&
                  setDisabledMother(true);

                break;
              case "father":
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                );
                setParticipante((prev) => ({
                  ...prev,
                  father: updatedParticipante.father,
                }));
                setBirthFatherPlaces({
                  nationality:
                    response?.locality?.part?.province?.country ||
                    response?.nationality,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });
                setDisabledFather(true);
                break;
              case "mother":
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                );
                setParticipante((prev) => ({
                  ...prev,
                  mother: updatedParticipante.mother,
                }));
                setBirthMotherPlaces({
                  nationality:
                    response?.locality?.part?.province?.country ||
                    response?.nationality,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });
                setDisabledMother(true);
                break;
              default:
                throw new Error(`Unknown typePeople: ${typePeople}`);
            }

            resetStateValidator(LoadState.LOADED, true);
          } else {
            resetStateValidator();
          }
        })
        .catch((error: any) => {
          resetStateValidator();
        });
    } else {
      resetStateValidator();
    }
  };

  const onChangeBirthNinioPlaces = (key: string, value: any) => {
    if (key === "nationality") {
      setParticipante((prev) => ({
        ...prev,
        ninio: {
          ...prev.ninio,
          nationality: value.id,
        },
      }));
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "birthProvince") {
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        ninio: {
          ...prev.ninio,
          birthLocality: value.id,
        },
      }));
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "birthPart") {
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const onChangeBirthFatherPlaces = (key: string, value: any) => {
    if (key === "nationality") {
      setParticipante((prev) => ({
        ...prev,
        father: {
          ...prev.father,
          nationality: value.id,
        },
      }));
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthProvince") {
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthPart") {
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        father: {
          ...prev.father,
          birthLocality: value.id,
        },
      }));
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const onChangeBirthMotherPlaces = (key: string, value: any) => {
    if (key === "nationality") {
      setParticipante((prev) => ({
        ...prev,
        mother: {
          ...prev.mother,
          nationality: value.id,
        },
      }));
    } else if (key === "birthProvince") {
      setBirthMotherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthPart") {
      setBirthMotherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        mother: {
          ...prev.mother,
          birthLocality: value.id,
        },
      }));
    }
  };

  const onChangeAddressIglesia = (key: string, value: any) => {
    if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        school: {
          ...prev.school,
          localityId: value.id,
        },
      }));

      if (schoolLevel != 0) {
        colegioService
          .findByLocalityIdAndSchoolLevelId(value.id, schoolLevel)
          .then((response: any) => {
            const colegiosSorted = sortByPropertyAndNameForSchool(
              response,
              "name",
            );
            setColegios(colegiosSorted);
          })
          .catch((error: any) => {
            console.log(Errors.ERROR_GET_SCHOOLS, error);
          });
      } else {
        colegioService
          .findByLocalityId(value.id)
          .then((response: any) => {
            const colegiosSorted = sortByPropertyAndNameForSchool(
              response,
              "name",
            );
            setColegios(colegiosSorted);
          })
          .catch((error: any) => {
            console.log(Errors.ERROR_GET_SCHOOLS, error);
          });
      }

      setAddressIglesia((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const onChangeSchoolLevel = (value: any) => {
    setSchoolLevel(value);

    if (value != 0) {
      colegioService
        .findByLocalityIdAndSchoolLevelId(
          Number(participante.school.localityId),
          value,
        )
        .then((response: any) => {
          const colegiosSorted = sortByPropertyAndNameForSchool(
            response,
            "name",
          );
          setColegios(colegiosSorted);
        })
        .catch((error: any) => {
          console.log(Errors.ERROR_GET_SCHOOLS, error);
        });
    } else {
      colegioService
        .findByLocalityId(Number(participante.school.localityId))
        .then((response: any) => {
          const colegiosSorted = sortByPropertyAndNameForSchool(
            response,
            "name",
          );
          setColegios(colegiosSorted);
        })
        .catch((error: any) => {
          console.log(Errors.ERROR_GET_SCHOOLS, error);
        });
    }
  };

  const handleFormChange = (key: AllKeys, value: any) => {
    const keys = key.split(".") as [TopLevelKeys, NestedKeys?];
    const isDocumentType = keys[keys.length - 1] === "document_type";

    if (keys.length === 1) {
      // Upload on top level
      setParticipante((prev) => ({
        ...prev,
        [keys[0]]: isDocumentType ? value.id : value,
      }));
    } else if (keys.length === 2) {
      // Upload on nested level
      const [parentKey, childKey] = keys;
      setParticipante((prev) => {
        const parentValue = prev[parentKey];
        if (typeof parentValue === "object" && parentValue !== null) {
          return {
            ...prev,
            [parentKey]: {
              ...parentValue,
              [childKey!]: isDocumentType ? value.id : value,
            },
          };
        } else {
          return prev;
        }
      });
    }
  };

  const handleCountryChange = (key: any, value: any, type: string) => {
    if (key === DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findProvincesByCountryId(value.id)
      .then((response: any) => {
        if (type === "iglesia") {
          setIglesiaProvinces(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PROVINCES, error);
      });
  };

  const handleProvinceChange = (key: any, value: any, type: string) => {
    if (key === DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findPartsByProvinceId(value.id)
      .then((response: any) => {
        if (type === "iglesia") {
          setIglesiaParts(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PARTS, error);
      });
  };

  const handlePartChange = (key: any, value: any, type: string) => {
    if (key === DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    locacionesService.findPartWithLocalities(value.id).then((response: any) => {
      const iglesiasOrdenadas = sortByProperty(
        response.localities.flatMap((locality: any) => {
          const localityName = locality.name;
          return locality.iglesias.map((iglesia: any) => {
            iglesia.name = `${iglesia.denomination}-(${localityName}-${locality.addresses[0].street})`;
            return iglesia;
          });
        }),
        "name"
      );

      setIglesias(iglesiasOrdenadas);
    });
  };

  const handleItemChange = (
    value: any,
    type: "groupId" | "nameId" | "turnoExpecificationId",
    valueName?: any,
  ) => {
    if (type === "groupId") {
      setParticipante((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          groupId: value,
        },
      }));

      actividadParroquialService
        .findActividadByActivityNameIdAndGroupId(
          participante.activity.nameId,
          value,
        )
        .then((response: any) => {
          if (response?.id) {
            setActivityId(response.id);
            turnoExpecificationService
              .getEncuentroByActivityId(response.id)
              .then((response: any) => {
                setEncuentro(response[0]);
              })
              .catch((error: any) => {
                console.error("Error al traer turnos", error);
              });
          }
        })
        .catch((error: any) => {
          console.error("Error al buscar actividad parroquial", error);
        });
    } else if (type === "nameId") {
      updateActividadParroquialFlags(
        valueName,
        setIsActivityParroquialCatequesis,
        setIsActivityParroquialMovimientoMisionero,
      );

      setParticipante((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          nameId: value,
        },
      }));

      grupoParroquialService
        .getGrupoWithOnlyIdAndName(value)
        .then((response: any) => {
          const sortedResponse = sortByProperty(response, "name");
          setGroups(sortedResponse);
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else if (type === "turnoExpecificationId") {
      setParticipante((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          turnoExpecificationId: value,
        },
      }));
    }
  };

  const validateParentFields = () => {
    const { father, mother } = participante;
    const fatherFilled =
      father.name && father.surname && father.document && father.document_type;
    const motherFilled =
      mother.name && mother.surname && mother.document && mother.document_type;
    return fatherFilled || motherFilled;
  };

  const validateParentContactFields = () => {
    const { father, mother } = participante;
    const fatherFilled = father.email && father.phone;
    const motherFilled = mother.email && mother.phone;
    return fatherFilled || motherFilled;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isActivityParroquialCatequesis && !validateParentFields()) {
      addNotification(queryClient, "error", "Debe completar la información del padre o de la madre.");
      return;
    }

    if (isActivityParroquialCatequesis && !validateParentContactFields()) {
      addNotification(queryClient, "error", "Debe completar la información del padre o de la madre.");
      return;
    }

    if (!isValidParticipantLocation()) {
      addNotification(
        queryClient,
        "error",
        "Debe completar la localización del participante. Opciones válidas: dejar en blanco, seleccionar solo el país, o completar todos los campos (País, Provincia, Partido, Localidad)."
      );

      return;
    }

    const formattedData = formatParticipanteData(
      participante,
      isActivityParroquialCatequesis,
      isActivityParroquialMovimientoMisionero,
    );
    //@ts-ignore
    formattedData.activityId = activityId;
    //@ts-ignore
    formattedData.encuentroId = encuentro?.id;
    participanteActividadParroquialService
      .createParticipante(formattedData)
      .then((response: any) => {
        addNotification(
          queryClient,
          "success",
          AltaParticipanteTextos.PARTICIPANTE_CREADO_CORRECTAMENTE,
        );
        navigate("/panel/actividad-parroquial/listado-integrantes");
      })
      .catch((error: any) => {
        console.error("Error al crear participante", error);
        addNotification(
          queryClient,
          "error",
          AltaParticipanteTextos.ERROR_CREAR_PARTICIPANTE,
        );
      });
  };

  const isValidParticipantLocation = () => {
    // Define an array of participants along with their respective location data
    const participants = [
      { data: participante.ninio, places: birthNinioPlaces },
      { data: participante.mother, places: birthMotherPlaces },
      { data: participante.father, places: birthFatherPlaces },
    ].filter(p => p.data); // Remove null or undefined participants

    // If there are no participants, the validation passes
    if (participants.length === 0) return true;

    // Check if every participant meets one of the allowed conditions
    return participants.every(({ data, places }) => {

      const { nationality, birthProvince, birthPart, birthLocality } = places;

      // Option 1: All fields are empty
      const isEmpty = !nationality && !birthProvince && !birthPart && !birthLocality;

      // Option 2: Only country (nationality) is selected
      const hasOnlyCountry = nationality && !birthProvince && !birthPart && !birthLocality;

      // Option 3: All location fields are fully completed
      const hasAllFields = nationality && birthProvince && birthPart && birthLocality;

      return isEmpty || hasOnlyCountry || hasAllFields;
    });
  };

  return (
    <Box sx={PeopleActividadParroquialStyles.box1}>
      <LinksAndHeader handleGoBack={handleGoBack} nameId={participante.activity.nameId} groupId={participante.activity.groupId} />
      <Box sx={PeopleActividadParroquialStyles.divForm}>
        <form onSubmit={handleSubmit}>
          <ActividadParroquialInputs
            actividadParroquialNombres={actividadParroquialNombres}
            participante={participante}
            handleItemChange={handleItemChange}
            groups={groups}
            turnosExpecification={encuentro}
            disabledTurno={disabledTurno}
          />

          {(isActivityParroquialCatequesis ||
            isActivityParroquialMovimientoMisionero) && (
              <CargaEscuela
                participante={participante}
                handleFormChange={handleFormChange}
                disabledSchool={disabledSchool}
                schoolLevel={schoolLevel}
                levels={levels}
                onChangeSchoolLevel={onChangeSchoolLevel}
                addressIglesia={addressIglesia}
                onChangeAddressIglesia={onChangeAddressIglesia}
                isFetch={isFetch}
                loadState={loadState}
                colegios={colegios}
                setParticipante={setParticipante}
                optionsYears={optionsYears}
              />
            )}

          <DatosNinio
            participante={participante}
            handleFormChange={handleFormChange}
            disabledNinio={disabledNinio}
            inputLabelStyles={inputLabelStyles}
            handleFetchPerson={handleFetchPerson}
            resetStateValidator={resetStateValidator}
            setParticipante={setParticipante}
            birthPlaces={birthNinioPlaces}
            onChangeBirthPlaces={onChangeBirthNinioPlaces}
            isFetch={isFetch}
            loadState={loadState}
            isActivityParroquialCatequesis={isActivityParroquialCatequesis}
            isActivityParroquialMovimientoMisionero={
              isActivityParroquialMovimientoMisionero
            }
          />

          {(isActivityParroquialCatequesis ||
            isActivityParroquialMovimientoMisionero) && (
              <DatosIglesia
                participante={participante}
                handleFormChange={handleFormChange}
                disabledChurch={disabledChurch}
                inputLabelStyles={inputLabelStyles}
                handleCountryChange={handleCountryChange}
                handleProvinceChange={handleProvinceChange}
                handlePartChange={handlePartChange}
                countries={countries}
                iglesiaProvinces={iglesiaProvinces}
                iglesiaParts={iglesiaParts}
                iglesias={iglesias}
                iglesiaDirection={iglesiaDirection}
                loadState={loadState}
              />
            )}

          {(isActivityParroquialCatequesis ||
            isActivityParroquialMovimientoMisionero) && (
              <DatosPadre
                participante={participante}
                handleFormChange={handleFormChange}
                disabledFather={disabledFather}
                inputLabelStyles={inputLabelStyles}
                birthPlaces={birthFatherPlaces}
                onChangeBirthPlaces={onChangeBirthFatherPlaces}
                isFetch={isFetch}
                loadState={loadState}
                handleFetchPerson={handleFetchPerson}
                resetStateValidator={resetStateValidator}
                setParticipante={setParticipante}
              />
            )}

          {(isActivityParroquialCatequesis ||
            isActivityParroquialMovimientoMisionero) && (
              <DatosMadre
                participante={participante}
                handleFormChange={handleFormChange}
                disabledMother={disabledMother}
                inputLabelStyles={inputLabelStyles}
                birthPlaces={birthMotherPlaces}
                onChangeBirthPlaces={onChangeBirthMotherPlaces}
                isFetch={isFetch}
                loadState={loadState}
                handleFetchPerson={handleFetchPerson}
                resetStateValidator={resetStateValidator}
                setParticipante={setParticipante}
              />
            )}

          <Grid sx={stylesActividadParroquial.gridButton}>
            {!isEditRoute && (
              <Button
                type={FormTextSend.SUBMIT}
                variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
                color={CreateActividadParroquialEnum.PRIMARY}
                style={stylesActividadParroquial.buttonSave}
              >
                {CreateActividadParroquialEnum.GUARDAR}
              </Button>
            )}
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default CreateParticipante;
