import { SxProps, Theme } from "@mui/material";

export const formContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

export const formBox: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: 2,
  width: 400,
  padding: 3,
  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
  borderRadius: 2,
  backgroundColor: "#fff",
};

export const buttonContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
};
