import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { RegisterFormEnum, UserRoles } from '../../enums/components/componentsEnum';
import { stylesEditRolIglesiaOfUser } from './EditRolIglesiaOfUserStyles';
import RolesChangeModal from '../rolesChangeModal/RolesChangeModal';

const EditRolIglesiaOfUser = ({
    iglesiasOptions,
    user,
    idIgleContext,
    systemRolsOfUser,
    onRolesIglesiaChange,
    onChangeSelectedIglesia,
    isOnlyForChangeIglesia,
}: any) => {
    const [selectedIglesia, setSelectedIglesia] = React.useState(null);
    const [rolesIglesia, setRolesIglesia] = useState<string[]>([]);
    const isSuperAdmin = systemRolsOfUser?.includes(UserRoles.SUPER_ADMIN);
    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        const loadRoles = async () => {
            if (!user || !selectedIglesia) return;
    
            if (!isSuperAdmin) {
                await new Promise(resolve => setTimeout(resolve, 500));
                const roles = user?.rolesIglesia?.find((rol: any) => rol?.iglesia?.id === idIgleContext)?.roles || [];
                setRolesIglesia(roles);
                onRolesIglesiaChange(roles);
            }
        };
    
        loadRoles();
    }, [user, idIgleContext, isSuperAdmin]);

    const onIglesiaChange = (event: any, value: any) => {
        if (value === null) {
            setSelectedIglesia(null);
            onChangeSelectedIglesia(null);
            setRolesIglesia([]);
            onRolesIglesiaChange([]);
            return;
        }

        const selectedChurch = iglesiasOptions.find(
            (iglesia: any) => iglesia.denomination === value?.denomination,
        );
        setSelectedIglesia(selectedChurch);
        onChangeSelectedIglesia(selectedChurch);

        const roles = user.rolesIglesia.find((rol: any) => rol.iglesia?.id === selectedChurch?.id)?.roles || [];
        setRolesIglesia(roles);
        onRolesIglesiaChange(roles);
    };

    const getIglesiaLabel = ({ id, denomination }: any) =>
        `${denomination ?? ""} - ${id ?? ""}`;

    const handleRolesChange = (event: any, value: any) => {
        if (value.includes(UserRoles.DISABLED)) {
                setOpenModal(true);
        } else {
            setRolesIglesia(value);
            onRolesIglesiaChange(value);
        }
    };

    const handleConfirmChange = () => {
        setRolesIglesia([UserRoles.DISABLED]);
        onRolesIglesiaChange([UserRoles.DISABLED]);
        setOpenModal(false);
    };

    return (
        <Grid container spacing={2}>
            {
                isSuperAdmin && (
                    <Grid item xs={6}>
                        <Typography
                            variant={RegisterFormEnum.SUBTITLE1}
                            sx={stylesEditRolIglesiaOfUser.typography6}
                        >
                            {RegisterFormEnum.PARROQUIAS_DEL_USUARIO}
                        </Typography>
                        <Autocomplete
                            options={iglesiasOptions}
                            getOptionLabel={getIglesiaLabel}
                            value={selectedIglesia}
                            onChange={onIglesiaChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Iglesia"
                                    fullWidth
                                />
                            )}
                            fullWidth
                            disabled={isOnlyForChangeIglesia}
                        />
                    </Grid>
                )
            }
            <Grid item xs={isSuperAdmin ? 6 : 12}>
                <Typography
                    variant={RegisterFormEnum.SUBTITLE1}
                    sx={stylesEditRolIglesiaOfUser.typography6}
                >
                    {RegisterFormEnum.ROL_IGLESIA}
                </Typography>
                <Autocomplete
                    multiple
                    options={[
                        UserRoles.SACERDOTE,
                        UserRoles.ADMINISTRATOR,
                        UserRoles.SECRETARIA,
                        UserRoles.COORDINADOR,
                        UserRoles.DISABLED,
                        UserRoles.SACERDOTE_INVITADO,
                    ]}
                    value={rolesIglesia}
                    onChange={handleRolesChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Rol Iglesia"
                            placeholder="Seleccione"
                            fullWidth
                        />
                    )}
                    fullWidth
                    disabled={isOnlyForChangeIglesia}
                />
            </Grid>
            <RolesChangeModal open={openModal} onClose={() => setOpenModal(false)} onConfirm={handleConfirmChange} />
        </Grid>
    );
};

export default EditRolIglesiaOfUser;
