import React, { useEffect, useState } from "react";
import administratorService from "../../services/administrator";
import { Box, Button, TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GenericTable from "../../components/genericTable/GenericTable";
import { RoutesEnum } from "../../utils/RoutesEnum";
import { useNavigate } from "react-router-dom";
import iglesiasService from "../../services/iglesias";
import ProfilePage from "../Profile/ProfilePage";
import Avatar from "react-avatar";
import { List } from "@mui/icons-material";
import usersService from "../../services/users/UsersService";
import { addNotification } from "../../utils/notifications";
import DeleteUserModal from "../../components/deleteUserModal/DeleteUserModal";
import { queryClient } from "../../App";

const RowImage = (row: any) => {
  const [imageBackground, setImageBackground] = useState(null);

  useEffect(() => {
    if (!row.imageProfile) return;
    iglesiasService
      .getImage("/iglesia/get-image/", row.imageProfile)
      .then((response: any) => {
        try {
          //@ts-ignore
          setImageBackground(URL.createObjectURL(response));
        } catch (error) { }
      });
  }, []);


  return (
    <TableCell>
      {!!imageBackground && (
        <img
          style={{
            width: "80px",
            height: "80px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          src={imageBackground}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = "";
          }}
        />
      )}

      {
        <>
          {!imageBackground && (
            <Avatar
              size="80"
              textSizeRatio={1}
              round={true}
              name={row?.nombre + " " + row?.apellido}
            />
          )}
        </>
      }
    </TableCell>
  );
};

const getColorByIndex = (index: number) => {
  const colors = [
    "#FFB3BA",
    "#FFDFBA",
    "#FFFFBA",
    "#BAFFC9",
    "#BAE1FF",
    "#BABEFF",
    "#DABAFF",
    "#FFBAF4",
    "#FFBAD2",
    "#FFBABA",
    "#FFC8BA",
    "#FFF5BA",
    "#D5FFBA",
    "#BAFFD0",
    "#BAFBFF",
    "#C5BAFF",
    "#FFBAFF",
    "#FFBADB",
  ];
  return colors[index % colors.length];
};

const rolesColumns = [
  {
    name: "Imagen",
    accessor: "imageProfile",
    customReder: (row: any, column: any) => {
      return <RowImage {...row} />;
    },
  },
  {
    name: "Nombre",
    accessor: "nombre",
  },
  {
    name: "Email",
    accessor: "email",
    customReder: (row: any) => (
      <TableCell key={row.email}>
        {row.people?.email}
      </TableCell>
    )
  },
  {
    name: "Documento",
    accessor: "documento",
    customReder: (row: any) => (
      <TableCell key={row.documento}>
        {row.people?.document}
      </TableCell>
    )
  },
  // {
  //   name: "Roles",
  //   accessor: "rolesIglesia",
  //   customReder: (row: any, column: any) => {
  //     return (
  //       <TableCell key={row + column}>
  //         {!!row?.rolesIglesia && row?.rolesIglesia[0]?.roles?.length > 0 ? (
  //           row?.rolesIglesia[0]?.roles?.map((item: any, index: number) => (
  //             <span
  //               key={index}
  //               style={{
  //                 backgroundColor: getColorByIndex(index),
  //                 padding: "2%",
  //                 borderRadius: "5px",
  //                 marginRight: "5px",
  //               }}
  //             >
  //               {item}
  //             </span>
  //           ))
  //         ) : (
  //           <span
  //             style={{
  //               backgroundColor: getColorByIndex(8),
  //               padding: "2%",
  //               borderRadius: "5px",
  //               marginRight: "5px",
  //             }}
  //           >
  //             Sin rol en esta Iglesia
  //           </span>
  //         )}
  //       </TableCell>
  //     );
  //   },

  // },
];

const RolesList = ({ isAdmin }: any) => {
  const [profileSelected, setProfileSelected] = useState<any>(null);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleDeleteUser = (userId: any) => {
    setSelectedUserId(userId);
    setOpenDeleteModal(true);
  };

  const confirmDeleteUser = () => {
    if (selectedUserId) {
      usersService.addRoleDisabledToUser(selectedUserId)
        .then(() => {
          addNotification(queryClient, "success", "Usuario eliminado con éxito");
          window.location.reload();
        })
        .catch(() => {
          addNotification(queryClient, "error", "Error al eliminar usuario");
        });
    }
    setOpenDeleteModal(false);
  };

  let turnosActions = [
    {
      icon: (row: any) => <List />,
      onClick: (row: any) => {
        navigate(`/panel/mi-cuenta/roles-iglesia/${row.id}`, {
          state: { isAdmin },
        });
      },
    },
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {
        setDialogOpen(true);
        setProfileSelected({ item: row, isAdmin: isAdmin, owner: false });
      },
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => handleDeleteUser(row.id),
    },
  ];

  const fetchTurnos = async (page: number, pageSize: number) => {
    if (!isAdmin) {
      let result = (await administratorService.findAllUserByIglesia(page, pageSize));

      // Ensure result.data exists and is an array
      let users = Array.isArray(result.data) ? result.data : [];

      let resultFilter = users.filter((user: any) =>
        user.rolesIglesia &&
        user.rolesIglesia.every((roleEntry: any) =>
          roleEntry.roles && !roleEntry.roles.includes("Disabled")
        )
      );

      result.data = resultFilter;

      return result;
    } else {
      let result = await administratorService.findAll(page, pageSize);

      return result;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "1em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <p style={{ color: "black" }}></p>
        {dialogOpen && (
          <div
            style={{
              position: "absolute",
              top: "53%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              backgroundColor: "#3a8dbe",
              padding: "12px",
              zIndex: 100,
              opacity: 1,
              borderRadius: "10px",
            }}
          >
            <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
              <ProfilePage
                isAdmin={isAdmin}
                isOwner={false}
                custom={{
                  fullScreen: "100%",
                  backgroundColor: "transparent",
                  handleGoBack: () => {
                    setDialogOpen(false);
                  },
                  handleClose: () => {
                    setDialogOpen(false);
                  },
                  item: profileSelected.item,
                }}
              />
            </div>
          </div>
        )}
        <Button
          style={{ backgroundColor: "#3A8DBE" }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(RoutesEnum.REGISTER, { state: { isAdmin } });
          }}
        >
          Nuevo usuario +
        </Button>
        <DeleteUserModal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} onConfirm={confirmDeleteUser} />
        <GenericTable<any>
          //@ts-ignore
          columns={rolesColumns}
          actions={turnosActions}
          title="turnos"
          fetchData={fetchTurnos}
        />
      </Box>
    </Box>
  );
};

export default RolesList;
