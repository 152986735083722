import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import authService from "./auth/index";
import ApiResponse from "./ApiResponse";
import ObservableService, { ObservableEvents } from "./ObservableService";

const baseURL = process.env.REACT_APP_API_URL;

/**
 * This class is used to make requests to the server
 */
export class GenericService {
  protected readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL,
    });

    this.axiosInstance.interceptors.request.use(async (config: any) => {
      const token = await authService.getToken();
      if (token) {
        const iglesiaSelected = authService.getIglesiaSelected();
        config.headers.Authorization = `Bearer ${token}`;
        if (!!iglesiaSelected) {
          config.params = { id_iglesia: iglesiaSelected?.iglesia?.id };
        }
      }
      return config;
    });
  }

  private async rejectPromise<T>(error: ApiResponse<T>): Promise<T> {
    ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
      type: "error",
      message: error.getMessage(),
    });
    return new Promise((resolve, reject) => reject());
  }

  /**
   * This method is used to get data from the server
   * @param url
   * @returns
   */
  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: ApiResponse<T> = ApiResponse.fromAxiosResponse(
      await this.axiosInstance.get(url, config),
    );
    return response.isSuccess()
      ? response.getContent()
      : this.rejectPromise(response);
  }

  public async getBlob<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    try {
      let response = await this.axiosInstance.get(url, config);
      const formatingResponse: ApiResponse<T> =
        ApiResponse.fromBlobResponse(response);
      return formatingResponse.isSuccess()
        ? formatingResponse.getContent()
        : this.rejectPromise(formatingResponse);
    } catch (error) {
      //@ts-ignore
      if (error?.response?.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = function () {
          if (typeof reader.result === "string") {
            //ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, { type: 'error', message: JSON.parse(reader.result).error });
          }
        };
        //@ts-ignore

        reader.readAsText(error.response.data);
      }
      //@ts-ignore
      return new Promise((resolve, reject) => resolve(""));
    }
  }

  /**
   * This method is used to post data to the server
   * @param url
   * @param data
   * @returns
   */
  public async post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const response: ApiResponse<T> = ApiResponse.fromAxiosResponse(
      await this.axiosInstance.post(url, data, config),
    );
    return response.isSuccess()
      ? response.getContent()
      : this.rejectPromise(response);
  }

  /**
   * This method is used to update data from the server
   * @param url
   * @param data
   * @returns
   */
  public async put<T>(url: string, data: any): Promise<T> {
    const response: ApiResponse<T> = ApiResponse.fromAxiosResponse(
      await this.axiosInstance.put(url, data),
    );
    return response.isSuccess()
      ? response.getContent()
      : this.rejectPromise(response);
  }

  /**
   * This method is used to delete data from the server
   * @param url
   */
  public async delete<T>(url: string): Promise<T> {
    const response: ApiResponse<T> = ApiResponse.fromAxiosResponse(
      await this.axiosInstance.delete(url),
    );
    return response.isSuccess()
      ? response.getContent()
      : this.rejectPromise(response);
  }

  /**
   * This methos is used for upload image
   * @param url
   * @param image
   * @returns
   */
  public async uploadImage<T>(url: string, image: File): Promise<T> {
    const formData = new FormData();
    formData.append("image", image);

    const config: AxiosRequestConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const response: ApiResponse<any> = ApiResponse.fromAxiosResponse(
      await this.axiosInstance.post(url, formData, config),
    );
    return response.isSuccess()
      ? response.getContent()
      : this.rejectPromise(response);
  }

  public async getImage<T>(url: string, fileName: string): Promise<T> {
    return await this.getBlob(url + fileName, {
      responseType: "blob",
    });
  }
  
}
