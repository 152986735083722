import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class TurnoService extends GenericService {
  constructor() {
    super();
  }

  async create(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        "/turno/createTurno",
        data,
      );
      return { message: "Turno creado con éxito", data: response.data };
    } catch (error: any) {
      if (error.response?.data?.message) {

        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error inesperado al crear el turno.");
      }
    }
  }

  //TODO move to correct service
  async getTurnos(page: number, size: number, filter?: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turno/getTurnos/" + page + "/" + size + "?" + filter,
      );
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
    }
  }

  async getTurnosFilters(
    page: number,
    size: number,
    filter?: string,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turno/getTurnosFilters/" + page + "/" + size + "?" + filter,
      );
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
    }
  }

  async getTurnosWithOutExpecification(date: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/turno/getTurnosWithOutExpecification/${date}`,
      );
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
    }
  }

  async getTurnoById(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(`/turno/getTurnosById/${id}`);
      return response;
    } catch (error) {
      console.error("Error al obtener el turno:", error);
      throw error;
    }
  }

  async deleteTurno(id: number) {
    return await this.delete(`/turno/${id}`);
  }

  async deleteTurnoWithEventId(id: number) {
    return await this.delete(`/turno/deleteTurnoWithEventId/${id}`);
  }

  async getTurnoIdByDocumentAndSacramentType(
    document: string,
    sacramentType: string,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/turno/getTurnoIdByDocumentAndSacramentType/${document}/${sacramentType}`,
      );
      return response;
    } catch (error: any) {
      throw error;
    }
  }

}

const turnoService = new TurnoService();

export default turnoService;
