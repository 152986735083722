import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { stylesCreateTurno } from "./stylesCreateTurno";
import {
  CreateTurnoEnum,
  Periodicidad,
  ValuesEnum,
} from "../../../enums/pages/CreateTurnoEnum";
import {
  ScheduleAppointmentEnum,
  Sizes,
} from "../../../enums/components/componentsEnum";
import moment, { Moment } from "moment";
import React from "react";
import { addNotification } from "../../../utils/notifications";
import { queryClient } from "../../../App";
import {
  TurnoState,
  dias,
  handleDayProperties,
  initializeTurno,
  opcionesDiaNumericos,
  opcionesSemana,
} from "../../../utils/pages/TurnoUtils";
import iglesiasService from "../../../services/iglesias";
import turnoExpecificationService from "../../../services/turnosExpecification";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  convertHourToUTC,
  convertUTCToLocal,
} from "../../../utils/datesUtils/dateUtils";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";
import {
  CreateTurnoProps,
  LocationState,
} from "../../../interfaces/components/interfacesCreateTurno";
import {
  ActividadParroquialRoutes,
  ActividadParroquialTextos,
  CreateActividadParroquialEnum,
  ValuesLabelEnum,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import NavLinkAdministratorGroups from "../../actividadesParroquiales/createActividadParroquial/LinksAdministrationGroup";
import { stylesActividadParroquial } from "../../actividadesParroquiales/createActividadParroquial/CreateActividadParroquialStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sortByProperty } from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";

const CreateTurno: React.FC<CreateTurnoProps> = ({
  isActividadParroquial = false,
}) => {
  const navigate = useNavigate();
  const [turnos, setTurnos] = useState<TurnoState[]>([
    { ...initializeTurno() },
  ]);
  const location = useLocation();
  const locationState = location.state as LocationState;
  const encuentro = locationState?.encuentro;
  const turnoId = location.state?.turnoId;
  const actividadParroquialName = locationState?.actividadParroquialNombre;
  const groupParroquialName = locationState?.grupoParroquial;
  const activityId = locationState?.activityId;
  const coordinators = locationState?.coordinators;
  const [sectores, setSectores]: any = useState([]);
  const [diasSeleccionados, setDiasSeleccionados] = useState<string[][]>([[]]);
  const [isEdition, setIsEdition] = useState<boolean[]>([true]);

  useEffect(() => {
    iglesiasService
      .getSectores()
      .then((result) => {
        const sortedResponse = sortByProperty(result.sectores, "description");
        setSectores(sortedResponse);
      })
      .catch((error) => {
        console.log(CreateTurnoEnum.ERROR_OBTENER_SECTORES, error);
      });

    if (isActividadParroquial) {
      setTurnos(
        turnos.map((turno) => ({
          ...turno,
          type: "ActividadParroquial",
          groupParroquialId: String(groupParroquialName.id),
          actividadParroquialId: String(actividadParroquialName.id),
        })),
      );
    }
    setDiasSeleccionados(turnos.map(() => []));
  }, []);

  useEffect(() => {
    if (encuentro?.parentTurnoId) {
      turnoExpecificationService
        .getTurnosExpecificationByParentId(encuentro?.parentTurnoId)
        .then((response: any) => {
          const turnosAjustados = response.data.map((turno: any) => ({
            id: turno.id,
            type: turno.type,
            criteryFeriados: turno.criteryFeriados,
            periodicity: turno.periodicity,
            enabledDays: turno.enabledDays,
            startTime: turno.startTime ? new Date(turno.startTime) : null,
            endTimeRepetition: turno.endTimeRepetition
              ? new Date(turno.endTimeRepetition)
              : null,
            startHour: convertUTCToLocal(turno.startHour),
            endHour: convertUTCToLocal(turno.endHour),
            sectorId: turno.sector ? turno.sector.id : "",
            sacerdoteId: turno.coordinador ? turno.coordinador.id : "",
            week: turno.week || "",
            day: turno.day || "",
            slots: turno.slots || null,
            startHourSpecial: turno.startHourSpecial || null,
            endHourSpecial: turno.endHourSpecial || null,
            actividadParroquialId: turno.actividadParroquialId || "",
            groupParroquialId: turno.groupParroquialId || "",
            scope: turno.scope || null,
            activityId: turno.activityId || "",
            parentId: turno.parentId || "",
          }));

          const initialState = turnosAjustados.map(() => false);
          setIsEdition(initialState);
          setTurnos(turnosAjustados);
          setDiasSeleccionados(
            turnosAjustados.map((t: { enabledDays: any }) => t.enabledDays),
          );
        })
        .catch((error: any) => {
          console.log("Error al obtener los datos del turno", error);
        });
    }
  }, [encuentro?.parentTurnoId]);

  const handleAddTurno = () => {
    setTurnos([...turnos, { ...initializeTurno() }]);
    setIsEdition((prevIsEdition) => [...prevIsEdition, true]);
  };

  const handleRemoveTurno = (index: number) => {
    const newTurnos = [...turnos];
    newTurnos.splice(index, 1);
    setTurnos(newTurnos);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    for (const turno of turnos) {
      turno.startHour = convertHourToUTC(turno.startHour);
      turno.endHour = convertHourToUTC(turno.endHour);
      turno.startHourSpecial = turno.startHourSpecial
        ? convertHourToUTC(turno.startHourSpecial)
        : null;
      turno.endHourSpecial = turno.endHourSpecial
        ? convertHourToUTC(turno.endHourSpecial)
        : null;
      turno.activityId = activityId;
      turno.sectorId = turno.sectorId || "";

      if (!turno.periodicity) {
        addNotification(
          queryClient,
          "error",
          CreateTurnoEnum.ERROR_PERIODICIDAD,
        );
        return;
      }

      if (turno.criteryFeriados === "") {
        addNotification(
          queryClient,
          "error",
          CreateTurnoEnum.ERROR_CRITERIO_FERIADOS,
        );
        return;
      } else if (
        turno.criteryFeriados === ValuesEnum.HORARIO_ESPECIAL &&
        (!turno.startHourSpecial || !turno.endHourSpecial)
      ) {
        addNotification(
          queryClient,
          "error",
          CreateTurnoEnum.ERROR_HORARIO_ESPECIAL,
        );
        return;
      }

      if (
        turno.periodicity === ValuesEnum.DIARIA &&
        (!turno.enabledDays || turno.enabledDays.length === 0)
      ) {
        addNotification(
          queryClient,
          "error",
          "Por favor, seleccione al menos un día de la semana.",
        );
        return;
      } else if (
        turno.periodicity === ValuesEnum.MENSUAL &&
        (!turno.enabledDays || turno.enabledDays.length === 0)
      ) {
        addNotification(
          queryClient,
          "error",
          "Por favor, seleccione al menos un día del mes.",
        );
        if (!turno.week) {
          addNotification(
            queryClient,
            "error",
            "Por favor, seleccione una semana del mes.",
          );
        }
        return;
      } else if (turno.periodicity === ValuesEnum.POR_FECHA && !turno.day) {
        addNotification(
          queryClient,
          "error",
          "Por favor, seleccione un día del mes.",
        );
        return;
      }
    }

    if (encuentro?.parentTurnoId) {
      await turnoExpecificationService
        .updateExpecificacionAP(encuentro?.parentTurnoId, turnos)
        .then(() => {
          addNotification(queryClient, "success", CreateTurnoEnum.TURNO_SUCESS);
          navigate(-1);
        })
        .catch((error) => {
          const errorMessage =
            error?.response?.data?.message || "Ocurrió un error inesperado al actualizar el turno.";

          addNotification(queryClient, "error", errorMessage);
        });
    } else {
      await turnoExpecificationService
        .createTurnoForActivity(turnos)
        .then(() => {
          addNotification(queryClient, "success", CreateTurnoEnum.TURNO_SUCESS);
          navigate(-1);
        })
        .catch((error) => {
          const errorMessage =
            error?.response?.data?.message || "Ocurrió un error inesperado al crear el turno.";

          addNotification(queryClient, "error", errorMessage);
        });
    }

  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSelectDay = (day: string, index: number) => {
    setDiasSeleccionados((prevDiasSeleccionados) => {
      const newDiasSeleccionados = [...prevDiasSeleccionados];
      const turnoSelectedDays = [...(prevDiasSeleccionados[index] || [])];

      const isDaySelected = turnoSelectedDays.includes(day);
      if (isDaySelected) {
        newDiasSeleccionados[index] = turnoSelectedDays.filter(
          (d) => d !== day,
        );
      } else {
        newDiasSeleccionados[index] = [...turnoSelectedDays, day];
      }

      setTurnos((prevTurnos) =>
        prevTurnos.map((turno, i) =>
          i === index
            ? {
              ...turno,
              enabledDays: newDiasSeleccionados[index],
              day: "",
              week: "",
            }
            : turno,
        ),
      );

      return newDiasSeleccionados;
    });
  };

  type InputChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement
  >;
  type SelectChangeEvent = React.ChangeEvent<{ name?: string; value: unknown }>;

  const handleChange = (
    event: InputChangeEvent | SelectChangeEvent,
    index: number,
    field: keyof TurnoState,
  ) => {
    const { value } = event.target;

    setTurnos((prevTurnos) =>
      prevTurnos.map((turno, i) =>
        i === index ? { ...turno, [field]: value } : turno,
      ),
    );
  };

  const handlePeriodicityChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setTurnos((prevTurnos) =>
      prevTurnos.map((turno, i) =>
        i === index ? { ...turno, periodicity: value } : turno,
      ),
    );
  };

  const handleCriteryFeriadosChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setTurnos((prevTurnos) =>
      prevTurnos.map((turno, i) =>
        i === index ? { ...turno, criteryFeriados: value } : turno,
      ),
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={stylesCreateTurno.box1}>
        <Box sx={stylesCreateTurno.box2}>
          <Box sx={stylesCreateTurno.box3}>
            <IconButton onClick={handleGoBack} sx={{}}>
              <ArrowBackIcon />
            </IconButton>
            {/* TODO DATO DINAMICO CATEDRAL */}
            <Typography style={stylesCreateTurno.typography}>
              {CreateTurnoEnum.CATEDRAL_DE_LA_PLATA}
            </Typography>
          </Box>

          <Box sx={stylesCreateTurno.containerLinks}>
            <Typography
              variant="subtitle1"
              sx={stylesActividadParroquial.title}
            >
              {ActividadParroquialTextos.TituloAdministracionGrupo}
            </Typography>
            <NavLinkAdministratorGroups
              to={ActividadParroquialRoutes.AltaActividad}
              label={ActividadParroquialTextos.AltaActividad}
              isActiveStyle={stylesActividadParroquial.activeLink}
              defaultStyle={stylesActividadParroquial.italicLink}
            />
            <NavLinkAdministratorGroups
              label={ActividadParroquialTextos.ProgramarEncuentro}
              isActiveStyle={stylesActividadParroquial.activeLink}
              defaultStyle={stylesActividadParroquial.italicLink}
            />
            <NavLinkAdministratorGroups
              to={ActividadParroquialRoutes.ListadoEncuentros}
              label={ActividadParroquialTextos.ListadoEncuentros}
              isActiveStyle={stylesActividadParroquial.activeLink}
              defaultStyle={stylesActividadParroquial.italicLink}
            />
            <NavLinkAdministratorGroups
              to={ActividadParroquialRoutes.ListadoIntegrantes}
              label={ActividadParroquialTextos.ListadoIntegrantes}
              isActiveStyle={stylesActividadParroquial.activeLink}
              defaultStyle={stylesActividadParroquial.italicLink}
            />
            <NavLinkAdministratorGroups
              to={ActividadParroquialRoutes.AltaParticipantes}
              label={ActividadParroquialTextos.AltaParticipantes}
              isActiveStyle={stylesActividadParroquial.activeLink}
              defaultStyle={stylesActividadParroquial.italicLink}
            />
          </Box>
        </Box>
        <div style={stylesCreateTurno.divForm}>
          <form onSubmit={handleSubmit}>
            <Grid style={stylesCreateTurno.gridTipo}>
              <Typography style={stylesCreateTurno.typographyTipo}>
                {
                  CreateActividadParroquialEnum.SELECCIONE_LA_ACTIVIDAD_PARROQUIAL
                }
              </Typography>
              <Select
                labelId={ValuesLabelEnum.NAME_ID}
                id={ValuesEnum.NAME_ID}
                value={actividadParroquialName.id || ""}
                fullWidth
                size={Sizes.SMALL}
                style={stylesCreateTurno.selectTipo}
                disabled={true}
                required
              >
                <MenuItem value={actividadParroquialName.id || ""}>
                  {actividadParroquialName.name || "N/A"}
                </MenuItem>
              </Select>
            </Grid>

            <Grid style={stylesCreateTurno.gridTipo}>
              <Typography style={stylesCreateTurno.typographyTipo}>
                {CreateActividadParroquialEnum.SELECCIONE_GRUPO}
              </Typography>
              <Select
                labelId={ValuesLabelEnum.GROUP_ID}
                id={ValuesEnum.GROUP_ID}
                value={groupParroquialName.id || ""}
                fullWidth
                size={Sizes.SMALL}
                style={stylesCreateTurno.selectTipo}
                disabled={true}
                required
              >
                <MenuItem value={groupParroquialName.id || ""}>
                  {groupParroquialName.name || "N/A"}
                </MenuItem>
              </Select>
            </Grid>
            {turnos.map((turno, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`turno-${index}-content`}
                  id={`turno-${index}-header`}
                >
                  <Typography>Encuentro {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Formulario de cada turno */}
                  <Grid sx={stylesCreateTurno.gridFormControl}>
                    <Typography style={stylesCreateTurno.typography}>
                      {CreateTurnoEnum.CRITERIO_FERIADOS}
                    </Typography>
                    <FormControl
                      sx={stylesCreateTurno.formControlRadios}
                      disabled={!isEdition[index]}
                    >
                      <RadioGroup
                        aria-labelledby={ValuesEnum.DEMO_FORM_CONTROL_LABEL}
                        name={`${ValuesEnum.CRITERY_FERIADOS}-${index}`}
                        value={turno.criteryFeriados || ""}
                        onChange={(e) => handleCriteryFeriadosChange(index, e)}
                        sx={stylesCreateTurno.formRadioGroup}
                      >
                        <FormControlLabel
                          value={ValuesEnum.HORARIO_ESPECIAL}
                          sx={stylesCreateTurno.formRadioGroupItem}
                          control={<Radio />}
                          label={CreateTurnoEnum.HORARIOS_ESPECIALES}
                        />
                        <FormControlLabel
                          value={ValuesEnum.HORARIO_NORMAL}
                          sx={stylesCreateTurno.formRadioGroupItem}
                          control={<Radio />}
                          label={CreateTurnoEnum.IGUAL_DIAS_HABILES}
                        />
                        <FormControlLabel
                          value={ValuesEnum.SIN_ACTIVIDAD}
                          sx={stylesCreateTurno.formRadioGroupItem}
                          control={<Radio />}
                          label={CreateTurnoEnum.SIN_ACTIVIDAD}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Campos condicionales basados en criteryFeriados */}
                  {turno.criteryFeriados === ValuesEnum.HORARIO_ESPECIAL && (
                    <Box sx={stylesCreateTurno.boxAvatars}>
                      <TextField
                        label={CreateTurnoEnum.HORA_INICIO}
                        type="time"
                        value={turno.startHourSpecial || ""}
                        onChange={(e) =>
                          handleChange(e, index, "startHourSpecial")
                        }
                        margin={Sizes.NORMAL}
                        size={Sizes.SMALL}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                      <TextField
                        label={CreateTurnoEnum.HORA_FIN}
                        type="time"
                        value={turno.endHourSpecial || ""}
                        onChange={(e) =>
                          handleChange(e, index, "endHourSpecial")
                        }
                        margin={Sizes.NORMAL}
                        size={Sizes.SMALL}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Box>
                  )}

                  {/* Periodicidad */}
                  <Grid sx={stylesCreateTurno.gridFormControl}>
                    <Typography style={stylesCreateTurno.typography}>
                      {Periodicidad.PERIODICIDAD}
                    </Typography>
                    <FormControl
                      sx={stylesCreateTurno.formControlRadios}
                      disabled={!isEdition[index]}
                    >
                      <RadioGroup
                        aria-labelledby={ValuesEnum.DEMO_FORM_CONTROL_LABEL}
                        name={`${ValuesEnum.PERIODICITY}-${index}`}
                        value={turno.periodicity || ""}
                        onChange={(e) => handlePeriodicityChange(index, e)}
                        sx={stylesCreateTurno.formRadioGroup}
                      >
                        <Box sx={stylesCreateTurno.boxRadios}>
                          <FormControlLabel
                            value={ValuesEnum.DIARIA}
                            sx={stylesCreateTurno.formRadioGroupItem}
                            control={<Radio />}
                            label={Periodicidad.DIARIA}
                          />
                          <Typography sx={stylesCreateTurno.smallText}>
                            {Periodicidad.DIARA_DESCRIPCION}
                          </Typography>
                        </Box>
                        <Box sx={stylesCreateTurno.boxRadios}>
                          <FormControlLabel
                            value={ValuesEnum.MENSUAL}
                            sx={stylesCreateTurno.formRadioGroupItem}
                            control={<Radio />}
                            label={Periodicidad.MENSUAL}
                          />
                          <Typography sx={stylesCreateTurno.smallText}>
                            {Periodicidad.MENSUAL_DESCRIPCION}
                          </Typography>
                        </Box>
                        <Box sx={stylesCreateTurno.boxRadios}>
                          <FormControlLabel
                            value={ValuesEnum.POR_FECHA}
                            sx={stylesCreateTurno.formRadioGroupItem}
                            control={<Radio />}
                            label={Periodicidad.POR_FECHA}
                          />
                          <Typography sx={stylesCreateTurno.smallText}>
                            {Periodicidad.POR_FECHA_DESCRIPCION}
                          </Typography>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Campos condicionales basados en periodicidad */}
                  <Box sx={stylesCreateTurno.boxAvatars}>
                    {turno.periodicity === ValuesEnum.DIARIA && (
                      <>
                        <Stack
                          direction="row"
                          spacing={2}
                          style={stylesCreateTurno.stack}
                        >
                          {dias.map((dia) => (
                            <Avatar
                              key={dia}
                              style={
                                diasSeleccionados[index]?.includes(dia)
                                  ? {
                                    ...stylesCreateTurno.avatars,
                                    ...stylesCreateTurno.avatarsSelects,
                                  }
                                  : stylesCreateTurno.avatars
                              }
                              onClick={() => {
                                if (isEdition[index]) {
                                  handleSelectDay(dia, index);
                                } else {
                                  addNotification(queryClient, "error", "No se puede editar los días habilitado");
                                }
                              }}
                            >
                              {dia}
                            </Avatar>
                          ))}
                        </Stack>
                        <Typography style={stylesCreateTurno.typographyStack}>
                          {CreateTurnoEnum.SELECCIONAR_UNO_O_MAS_DIAS}
                        </Typography>
                      </>
                    )}
                    {turno.periodicity === ValuesEnum.MENSUAL && (
                      <>
                        <Stack
                          direction="row"
                          spacing={2}
                          style={stylesCreateTurno.stack}
                        >
                          {dias.map((dia) => (
                            <Avatar
                              key={dia}
                              style={
                                diasSeleccionados[index]?.includes(dia)
                                  ? {
                                    ...stylesCreateTurno.avatars,
                                    ...stylesCreateTurno.avatarsSelects,
                                  }
                                  : stylesCreateTurno.avatars
                              }
                              onClick={() => handleSelectDay(dia, index)}
                            >
                              {dia}
                            </Avatar>
                          ))}
                        </Stack>
                        <Select
                          labelId={`${ValuesEnum.SEMANA_SELECT_LABEL}-${index}`}
                          id={`${ValuesEnum.SEMANA_SELECT}-${index}`}
                          value={turno.week || ""}
                          onChange={(e) =>
                            handleChange(e as SelectChangeEvent, index, "week")
                          }
                          fullWidth
                          size={Sizes.SMALL}
                          style={stylesCreateTurno.semanaTipo}
                          disabled={!isEdition[index]}
                        >
                          {opcionesSemana.map((opcion: any) => (
                            <MenuItem key={opcion.value} value={opcion.value}>
                              {opcion.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography style={stylesCreateTurno.typographyStack}>
                          {CreateTurnoEnum.SELECCIONAR_DIAS_SEMANAS}
                        </Typography>
                      </>
                    )}
                    {turno.periodicity === ValuesEnum.POR_FECHA && (
                      <>
                        <Select
                          labelId={`${ValuesEnum.DIA_SELECT_LABEL}-${index}`}
                          id={`${ValuesEnum.DIA_SELECT}-${index}`}
                          value={turno.day || ""}
                          onChange={(e) =>
                            handleChange(e as SelectChangeEvent, index, "day")
                          }
                          fullWidth
                          size={Sizes.SMALL}
                          style={stylesCreateTurno.fechaTipo}
                          disabled={!isEdition[index]}
                        >
                          {opcionesDiaNumericos.map((opcion: any) => (
                            <MenuItem key={opcion.value} value={opcion.value}>
                              {opcion.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography style={stylesCreateTurno.typographyStack}>
                          {CreateTurnoEnum.SELECCIONAR_DIAS}
                        </Typography>
                      </>
                    )}
                  </Box>

                  {/* Campos de hora y sector */}
                  <Grid sx={stylesCreateTurno.gridHora}>
                    {turno.type !== SacramentoEnum.Misa &&
                      !isActividadParroquial && (
                        <TextField
                          id={`${ValuesEnum.CUPOS}-${index}`}
                          label={CreateTurnoEnum.CANTIDAD_CUPOS}
                          type="number"
                          size={Sizes.SMALL}
                          value={turno.slots || ""}
                          style={stylesCreateTurno.gridCupos}
                          onChange={(e) => handleChange(e, index, "slots")}
                          required
                          disabled={!isEdition[index]}
                        />
                      )}
                    <FormControl style={stylesCreateTurno.formControlSector}>
                      <InputLabel id={`${ValuesEnum.SECTOR_LABEL}-${index}`}>
                        {CreateTurnoEnum.SECTOR}
                      </InputLabel>
                      <Select
                        labelId={`${ValuesEnum.SECTOR_ID_LABEL}-${index}`}
                        id={`${ValuesEnum.SECTOR_ID}-${index}`}
                        value={turno.sectorId || ""}
                        label={CreateTurnoEnum.SECTOR}
                        onChange={(e) =>
                          handleChange(
                            e as SelectChangeEvent,
                            index,
                            "sectorId",
                          )
                        }
                        // sx={stylesCreateTurno.sectorTipo}
                        required
                        disabled={!isEdition[index]}
                      >
                        {sectores.map((sector: any) => (
                          <MenuItem key={sector.id} value={sector.id}>
                            {sector.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl style={stylesCreateTurno.formControlSacerdote}>
                      <InputLabel id={`${ValuesEnum.SACERDOTE_LABEL}-${index}`}>
                        {CreateTurnoEnum.COORDINADOR}
                      </InputLabel>
                      <Select
                        labelId={`${ValuesEnum.SACERDOTE_ID_LABEL}-${index}`}
                        id={`${ValuesEnum.SACEDORTE_ID}-${index}`}
                        label={CreateTurnoEnum.COORDINADOR}
                        value={turno.sacerdoteId || ""}
                        onChange={(e) =>
                          handleChange(
                            e as SelectChangeEvent,
                            index,
                            "sacerdoteId",
                          )
                        }
                        required
                        disabled={!isEdition[index]}
                      // sx={stylesCreateTurno.sectorTipo}
                      >
                        {coordinators.map((coordinator: any) => (
                          <MenuItem key={coordinator.id} value={coordinator.id}>
                            {coordinator.name} {coordinator.surname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Campos de fecha y hora */}
                  <Box sx={stylesCreateTurno.boxEnd}>
                    <TextField
                      label={CreateTurnoEnum.FECHA_DESDE}
                      type="date"
                      value={
                        turno.startTime
                          ? moment(turno.startTime).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => handleChange(e, index, "startTime")}
                      margin={Sizes.NORMAL}
                      size={Sizes.SMALL}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={!isEdition[index]}
                    />
                    <TextField
                      label={CreateTurnoEnum.FECHA_HASTA}
                      type="date"
                      value={
                        turno.endTimeRepetition
                          ? moment(turno.endTimeRepetition).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(e, index, "endTimeRepetition")
                      }
                      margin={Sizes.NORMAL}
                      size={Sizes.SMALL}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={!isEdition[index]}
                    />
                    <TextField
                      label={CreateTurnoEnum.HORA_INICIO}
                      type="time"
                      value={turno.startHour || ""}
                      onChange={(e) => handleChange(e, index, "startHour")}
                      margin={Sizes.NORMAL}
                      size={Sizes.SMALL}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={!isEdition[index]}
                    />
                    <TextField
                      label={CreateTurnoEnum.HORA_FIN}
                      type="time"
                      value={turno.endHour || ""}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const currentStartHour = turnos[index].startHour;
                        if (newValue <= currentStartHour) {
                          addNotification(queryClient, "error", "La hora de fin no puede ser anterior a la hora de inicio");
                        } else {
                          handleChange(e, index, "endHour");
                        }
                      }}
                      margin={Sizes.NORMAL}
                      size={Sizes.SMALL}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled={!isEdition[index]}
                    />
                  </Box>

                  {/* Botones para eliminar turno */}
                  <IconButton
                    onClick={() => handleRemoveTurno(index)}
                    sx={{ position: "absolute", bottom: 8, right: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </AccordionDetails>
              </Accordion>
            ))}

            {/* Botones para agregar turno y enviar formulario */}
            <Grid sx={stylesCreateTurno.gridButton}>
              <Button
                type="button"
                onClick={handleAddTurno}
                variant="contained"
                color="primary"
                sx={{ mr: 2 }}
              >
                Agregar Turno
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {encuentro?.parentTurnoId
                  ? CreateTurnoEnum.ACTUALIZAR_TURNO
                  : CreateTurnoEnum.CREAR_TURNO}
              </Button>
            </Grid>
          </form>
        </div>
      </Box>
    </LocalizationProvider>
  );
};

export default CreateTurno;
