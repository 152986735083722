import { Box, Grid } from "@mui/material";
import ChildForm from "../forms/childForm";
import AdultForm from "../forms/adultForm";
import { StepsEnum } from "../../enums/components/componentsEnum";
import { FamilyStepStyles } from "./StepsStyles";
import React from "react";
import { RolSacramentoEnum } from "../../enums/RolSacramentoEnum";

const FamilyStep = ({
  formData,
  handleFormChange,
  errors,
  childs,
  adults,
}: any): JSX.Element => {
  return (
    <Grid
      key={StepsEnum.CONTAINER}
      container
      spacing={10}
      sx={FamilyStepStyles.grid}
    >
      {childs.map((child: any, index: any) => {
        return (
          <Grid key={index} item xs={4}>
            <ChildForm
              title={child.label}
              role={child.role}
              formData={formData[child.accessor]}
              onChange={(key: any, value: any) =>
                handleFormChange(`${child.accessor}.${key}`, value)
              }
              errors={errors.child}
              setFormData={null as any}
            />
          </Grid>
        );
      })}
      {adults.map((adult: any, index: any) => {
        return (
          <React.Fragment key={`adult-${index}`}>
            <Grid key={index} item xs={4}>
              <AdultForm
                title={adult.label}
                role={adult.role}
                formData={formData[adult.accessor]}
                onChange={(key: any, value: any) => {
                  handleFormChange(`${adult.accessor}.${key}`, value);
                }}
                statusCivil={!!adult.statusCivil && adult.statusCivil}
                errors={errors[adult.accessor]}
                formDataOfChild={formData.child}
                isMother={adult.accessor === RolSacramentoEnum.mother}
                isFather={adult.accessor=== RolSacramentoEnum.father}
              />
            </Grid>
            {!!adult.extraData && adult.extraData()}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default FamilyStep;
