import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class IglesiasService extends GenericService {
  constructor() {
    super();
  }

  async getAll(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get("/iglesia");
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las iglesias");
      return [];
    }
  }

  async getOne(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(`/iglesia/${id}`);
      return response;
    } catch (error) {
      console.log(`Error al obtener la iglesia con ID ${id}`);
      return {};
    }
  }

  async getOneByLocalityId(localityId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/iglesia/by-locality/${localityId}`,
      );
      return response;
    } catch (error) {
      console.log(`Error al obtener la iglesia con ID ${localityId}`);
      return {};
    }
  }

  async create(iglesias: any[]): Promise<any> {
    try {
      const response: AxiosResponse<any[]> = await this.post(
        "/iglesia",
        iglesias,
      );
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
      return [];
    }
  }

  async update(id: number, parish: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.put(
        `/iglesia/${id}`,
        parish,
      );
      return response;
    } catch (error) {
      console.log(`Error al actualizar la iglesia con ID ${id}`);
      return {};
    }
  }

  async remove(id: number): Promise<void> {
    try {
      await this.delete(`/iglesia/${id}`);
    } catch (error) {
      console.log(`Error al eliminar la iglesia con ID ${id}`);
    }
  }

  async searchByLocation(location: string): Promise<any> {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/iglesia/search/${location}`,
      );
      return response;
    } catch (error) {
      console.log(
        `Error al obtener las iglesias en la localidad de ${location}`,
      );
    }
  }

  async createEvento(id: number, evento: any): Promise<any> {
    try {
      const iglesia = await this.getOne(id);
      evento.iglesia = iglesia;
      const response: AxiosResponse<any> = await this.post(
        `/iglesia/${id}/calendario`,
        evento,
      );
      return response;
    } catch (error) {
      console.log("Error al crear el evento");
    }
  }

  async getEventos(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/iglesia/${id}/calendario`,
      );
      return response;
    } catch (error) {
      console.log(`Error al obtener los eventos de la iglesia con ID ${id}`);
    }
  }

  async getEvento(eventoId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/iglesia/calendario/${eventoId}`,
      );
      return response;
    } catch (error) {
      console.log(`Error al obtener el evento con ID ${eventoId}`);
    }
  }

  async getSectoresList(page: number, pageSize: number): Promise<any> {
    return await this.get(`/sector/getSectors/${page}/${pageSize}`);
  }

  async getSectores(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(`/iglesia/sectores`);
      return response;
    } catch (error) {
      console.log(`Error al obtener los sectores`);
    }
  }

  async getSacerdotes(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `iglesia/sacerdotes/findByRolIglesiaSacerdote`,
      );
      return response;
    } catch (error) {
      throw new Error(`Error al obtener los sectores`);
    }
  }

  async getIglesiaById(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/iglesia/findIglesiaById`,
      );
      return response;
    } catch (error) {
      throw new Error(`Error al obtener la iglesia`);
    }
  }

  async uploadImageHandler(image: File, iglesiaId: string): Promise<any> {
    try {
      const response = await this.uploadImage(
        "/iglesia/upload-image/" + iglesiaId,
        image,
      );
      return response;
    } catch (error) {
      throw new Error("Error en subir la imagen");
    }
  }

  async getImageByIdIgle(idIgle: string): Promise<string> {
    try {
      const response = await this.axiosInstance.get(`/iglesia/get-image/${idIgle}`);
  
      return response.data.imageUrl;
    } catch (error: any) {
      console.error("Error al obtener la imagen:", error);
      if (error?.response) {
        console.error("Detalles del error:", JSON.stringify(error.response.data));
      }
      throw new Error("Error al obtener la imagen");
    }
  }
  
}

const iglesiasService = new IglesiasService();

export default iglesiasService;
