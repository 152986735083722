import { Button, Dialog,DialogActions,DialogContent,DialogTitle, Typography } from "@mui/material";

const DeleteUserModal = ({ open, onClose, onConfirm }: any) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" color="error">
                    Confirmación de Eliminación
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    ¿Está seguro de que desea eliminar este usuario?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="error">
                    Cancelar
                </Button>
                <Button onClick={onConfirm} variant="contained" color="primary">
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserModal;
