import { useState } from "react";
import { useLocation } from "react-router-dom";
import administratorService from "../../services/administrator";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import {
  errorStyleOk,
  gridErrorStyle,
  errorStyle,
  stylesGridContainer,
  stylesDivGeneral,
  stylesFirstTypography,
} from "./ResetPasswordPageStyles";
import { validatePassword } from "../../components/passwordValidation";
import { logout } from "../../utils/auth";

const isResetPassword = (location: ReturnType<typeof useLocation>) => {
  const pathname = location.pathname;
  return pathname.includes("reset_password");
};

const ResetPasswordPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const validateAndSetPassword = (newPassword: string) => {
    setPassword(newPassword);

    if (attemptedSubmit) {
      setPasswordErrors(validatePassword(newPassword));
    }

    if (confirmPassword) {
      setPasswordsMatch(newPassword === confirmPassword);
    }
  };

  const validateAndSetConfirmPassword = (newConfirmPassword: string) => {
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  const handleResetPassword = async () => {
    setAttemptedSubmit(true);
    const errors = validatePassword(password);
    setPasswordErrors(errors);
  
    if (errors.length > 0 || password !== confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
      return;
    }
  
    try {
      const response = await administratorService.resetPassword(token!, password);
      setShowAlert(true);
      setMessage(response.message);
      setTimeout(() => {
        logout();
        window.location.href = "/login";
      }, 2500);
      
    } catch (error: any) {
      setError(error.message);
      setShowAlert(true);
      return;
    }
  };

  return (
    <div style={stylesDivGeneral}>
      <Grid container style={stylesGridContainer}>
        <Typography
          variant="subtitle1"
          sx={stylesFirstTypography}
        >
          {isResetPassword(location)
            ? "Restablecimiento de contraseña"
            : "Establece tu contraseña"}
        </Typography>

        <Grid item xs={12}>
          <TextField
            type="password"
            label="New Password"
            placeholder="New Password"
            fullWidth
            autoComplete="off"
            value={password}
            onChange={(e) => validateAndSetPassword(e.target.value)}
            error={attemptedSubmit && (passwordErrors.length > 0 || !passwordsMatch)}
            helperText={
              attemptedSubmit
                ? passwordErrors.length > 0
                  ? passwordErrors.map((error, index) => <div key={index}>{error}</div>)
                  : ""
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "2%" }}>
          <TextField
            type="password"
            fullWidth
            label="Confirm Password"
            autoComplete="off"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => validateAndSetConfirmPassword(e.target.value)}
            error={attemptedSubmit && !passwordsMatch}
            helperText={attemptedSubmit && !passwordsMatch ? "Las contraseñas no coinciden" : ""}
          />
        </Grid>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: "20px" }}
          fullWidth
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>

        <Grid style={gridErrorStyle}>
          {showAlert &&
            (error ? (
              <Alert style={errorStyle} severity="error">
                {error}
              </Alert>
            ) : (
              <Alert style={errorStyleOk} severity="success">
                {message}
              </Alert>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPasswordPage;
