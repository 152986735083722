import React from "react";
import { Box, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../components/genericTable/GenericTable";
import turnoExpecificationService from "../../services/turnosExpecification";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  formatCriteryFeriados,
  formatPeriodicity,
} from "../../utils/pages/TurnoUtils";
import moment from "moment";
import {
  convertUTCToLocal,
  getOnlyDate,
} from "../../utils/datesUtils/dateUtils";
import turnoService from "../../services/turnos";
import ObservableService, { ObservableEvents } from "../../services/ObservableService";

const ListadoDeMisasProgramadas = () => {
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = React.useState(new Date());

  const turnosColumns = [
    {
      name: "Fecha de inicio / fin",
      accessor: "startTime",
      customReder: (row: any, column: any) => {
        const date = new Date(row.startTime);
        return (
          <TableCell>
            {row.startTime && row.endTimeRepetition
              ? `${moment(getOnlyDate(row.startTime)).format("DD-MM-YYYY")} / ${moment(getOnlyDate(row.endTimeRepetition)).format("DD-MM-YYYY")}`
              : "-"}
          </TableCell>
        );
      },
    },
    {
      name: "Hora de Inicio / Fin",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.startHour && row.endHour
              ? `${convertUTCToLocal(row.startHour)} / ${convertUTCToLocal(row.endHour)}`
              : "-"}
          </TableCell>
        );
      },
    },
    {
      name: "Sacerdote",
      accessor: "sacerdote",
      customReder: (row: any, column: any) => {
        const sacerdote = row.sacerdote;
        return (
          <TableCell>
            {" "}
            {sacerdote ? `${sacerdote.nombre} ${sacerdote.apellido}` : "-"}{" "}
          </TableCell>
        );
      },
    },
    {
      name: "Periodicidad",
      accessor: "",
      customReder: (row: any, column: any) => {
        return <TableCell>{formatPeriodicity(row.periodicity)}</TableCell>;
      },
    },
    {
      name: "Semana",
      accessor: "week",
      customReder: (row: any, column: any) => {
        return <TableCell> {row.week ? `${row.week}` : "-"} </TableCell>;
      },
    },
    {
      name: "Día",
      accessor: "day",
      customReder: (row: any, column: any) => {
        return <TableCell> {row.day ? `${row.day}` : "-"} </TableCell>;
      },
    },
    {
      name: "Días Habilitados",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.enabledDays.length > 0 ? row.enabledDays.join(", ") : "-"}
          </TableCell>
        );
      },
    },
    {
      name: "Criterio Feriados",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>{formatCriteryFeriados(row.criteryFeriados)}</TableCell>
        );
      },
    },
    {
      name: "Sector",
      accessor: "sector.description",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>{row.sector ? row.sector.description : "-"}</TableCell>
        );
      },
    },
  ];

  /**
   * Actions for turnos
   */
  const turnosActions = [
    {
      icon: (row: any) => <VisibilityIcon />,
      onClick: (row: any) => {
        navigate(`/panel/misas/list/${row.id}`, {});
      },
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar esta especificación?",
        );
        if (confirmDelete) {
          turnoExpecificationService.deleteAllSimpleTurnsAsociatedOfMisaWithExpecificationId(row.id).then((response: any) => {
            setForceRefresh(new Date());
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "success",
              message: "Se elimino el evento correctamente",
            });
          });
        }
      },
    },
  ];

  const fetchTurnos = async (
    page: number,
    pageSize: number,
    filter?: string,
  ) => {
    const response = await turnoExpecificationService.getExcificationMisas(
      page,
      pageSize,
    );

    return response;
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <div
          style={{
            paddingBottom: "25px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        ></div>
        <GenericTable<any>
          filtersData={[{ param: "status", value: "pending" }]}
          refresh={forceRefresh}
          columns={turnosColumns}
          actions={turnosActions}
          title="Misas programadas"
          fetchData={fetchTurnos}
          filters={() => {
            return <></>;
          }}
        />
      </Box>
    </Box>
  );
};

export default ListadoDeMisasProgramadas;
