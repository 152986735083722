import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";
import { ErrorMessages } from "../../utils/services";

export class AdministratorService extends GenericService {
  constructor() {
    super();
  }

  async findAll(page: number, pageSize: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/usuarios/administrators/" + page + "/" + pageSize,
      );
      return response;
    } catch (error: any) {
      console.log("Error al obtener todos los usuarios administradores");
      return [];
    }
  }

  async findAllWithPagination(
    page: number,
    pageSize: number,
    filter?: string,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/usuarios/administrators/" + page + "/" + pageSize
      );
      return response;
    } catch (error) {
      console.log("Error al obtener todos los usuarios administradores");
      return [];
    }
  }

  async findOne(document: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/usuarios/administrator/" + document,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener todos los usuarios administradores");
      return [];
    }
  }

  async findRolesIglesiaOfUserWithPagination(
    document: string,
    page: number,
    pageSize: number,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/usuarios/findRolesIglesiaOfUserWithPagination/${document}/${page}/${pageSize}`,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener los roles de la iglesia del usuario");
      return [];
    }
  }

  async findById(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/usuarios/administrator/id/" + id,
      );
      return response;
    } catch (error) {
      console.log("Error al crear el usuario administrador");
      return [];
    }
  }

  async findAllUserByIglesia(page: number, pageSize: number): Promise<any> {
    try {
      //TODO get only administrators
      const response: AxiosResponse<any> = await this.get(
        "/usuarios/usersByIglesias/" + page + "/" + pageSize,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener todos los usuarios administradores");
      return [];
    }
  }

  async requestPasswordReset(idUser: number, email: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        "/auth/requestPasswordReset/",
        {
          email: email,
          idUser: idUser
        },
      );
      //@ts-ignore
      if (response && response.message) {
        //@ts-ignore
        return response.message;
      } else {
        return "No se recibió un mensaje de respuesta del servidor";
      }
    } catch (error) {
      throw new Error("Error al solicitar el cambio de contraseña");
    }
  }

  async resetPassword(token: string, password: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        "/auth/resetPassword/",
        { token, newPassword: password }
      );
      return response;
    } catch (error: any) {
      let customMessage;

      if (error.response?.data?.error) {
        switch (error.response.data.error) {
          case "Token inválido":
            customMessage = "El enlace de restablecimiento es inválido. Intente solicitar uno nuevo.";
            break;
          case "El token ha expirado":
            customMessage = "El enlace de restablecimiento ha expirado. Solicite un nuevo correo.";
            break;
          default:
            customMessage = "Ocurrió un error inesperado. Intente nuevamente.";
        }
      } else {
        customMessage = "No se pudo conectar con el servidor. Intente nuevamente.";
      }

      throw new Error(customMessage);
    }
  }

  async editUser(id: number, data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.put(
        "/usuarios/administrator/edit/" + id,
        data,
      );
      return response;
    } catch (error) {
      throw new Error("Error al editar el usuario administrador");
    }
  }

  async getCoordinatorsByActivityParroquialId(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/usuarios/getCoordinatorsByActivityParroquialId/" + id,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener los coordinadores por id de actividad");
      return [];
    }
  }
}

const administratorService = new AdministratorService();

export default administratorService;
