import { useState } from "react";
import { Box, Button, TextField, Select, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SacramentosMayuscula } from "../../enums/components/componentsEnum";
import { eventEdit } from "../../pages/turnos/EventList/EventList";
import { formContainer, formBox, buttonContainer } from "./CompleteSacramentFormStyles";
import turnoService from "../../services/turnos";

type SacramentType = "Bautismo" | "Casamiento" | "Comunion" | "Confirmacion";

const CompleteSacramentForm = () => {
    const [document, setDocument] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [sacrament, setSacrament] = useState<SacramentType | "">("");

    const sacramentsList = [SacramentosMayuscula.BAUTISMO, SacramentosMayuscula.MATRIMONIO, SacramentosMayuscula.COMUNION, SacramentosMayuscula.CONFIRMACION];

    const handleSubmit = async () => {
        if (!document || !sacrament) {
            setError("Debe ingresar un documento y seleccionar un sacramento.");
            return;
        }

        try {
            const turnoId = await turnoService.getTurnoIdByDocumentAndSacramentType(document, sacrament);

            if (turnoId) {
                navigate(eventEdit[sacrament], { state: { eventId: turnoId } });
            } else {
                setError("El usuario no tiene el sacramento iniciado.");
            }
        } catch (err: any) {
            if (err.response?.data?.errorCode) {
                setError(err.response.data.errorCode);
            } else {
                setError("Error al verificar la información. Intente nuevamente.");
            }
        }
    };

    return (
        <Box sx={formContainer}>
            <Box sx={formBox}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>Completar Datos del Sacramento</Typography>

                <TextField
                    label="Documento del usuario"
                    variant="outlined"
                    fullWidth
                    value={document}
                    onChange={(e) => setDocument(e.target.value)}
                />

                <Select
                    value={sacrament}
                    onChange={(e) => setSacrament(e.target.value as SacramentType)}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>Seleccione un sacramento</MenuItem>
                    {sacramentsList.map((s) => (
                        <MenuItem key={s} value={s}>{s}</MenuItem>
                    ))}
                </Select>

                {error && <Typography color="error">{error}</Typography>}

                <Box sx={buttonContainer}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Aceptar</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CompleteSacramentForm;
