import { useMutation } from "react-query";
import { IglesiasService } from "../../services/iglesias";
import { useState, useEffect } from "react";
import RegisterForm from "../../components/forms/registerForm";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { sortByProperty } from "../../utils/actividadParroquialUtils/UtilsActividadParroquial";

const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [churches, setChurches] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const iglesiasService = new IglesiasService();
  const location = useLocation();
  const isAdmin = location.state?.isAdmin;
  const IglesiasMutation = useMutation<any, Error, void>(
    () => iglesiasService.getAll(),
    {
      onSuccess: (data) => {
        setIsLoading(false);
        const dataSort = sortByProperty(data, "name");
        setChurches(dataSort)
      },
      onError: () => {
        setIsLoading(false);
        setIsError(true);
      },
    },
  );

  useEffect(() => {
    IglesiasMutation.mutate();
  }, []);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <RegisterForm isAdmin={isAdmin} iglesias={churches} />
    </Box>
  );
};

export default RegisterPage;
