import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Paper,
  Typography,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import authService from "../../../services/auth";
import EmailValidation from "../../emailValidation";
import iglesiasService from "../../../services/iglesias";
import DocumentTypeComponent from "../../autocomplete/documentType/DocumentTypeComponent";
import {
  createFormStateInRegister,
  createFormStateInRegisterForUserFetched,
  getDocumentTypes,
  initializeErrors,
  validateFormUtils,
} from "../../../utils/forms/formsUtils";
import {
  CONFIRMAR_CONTRASENA,
  CONFIRM_PASSWORD,
  CONTAINED,
  DATE,
  DataPersonal,
  DatosPersonales,
  FILE,
  FormTextSend,
  IGLESIA,
  InOrder,
  LABEL,
  NEW_PASSWORD,
  Register,
  RegisterFormEnum,
  Sizes,
  State,
  TEXT,
  USER_TYPE,
  Updated,
  Urls,
  UserRoles,
} from "../../../enums/components/componentsEnum";
import {
  inputLabelStyles,
  stylesRegister,
  stylesRegisterForm,
} from "./RegisterFormStyles";
import peopleService from "../../../services/people";
import usersService from "../../../services/users/UsersService";
import { queryClient } from "../../../App";
import { addNotification } from "../../../utils/notifications";
import RolesChangeModal from "../../rolesChangeModal/RolesChangeModal";
moment.locale("es");

const RegisterForm = ({ iglesias, isAdmin }: any) => {
  const [church, setChurch] = useState<any>(null);
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [document, setDocument] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [errors, setErrors] =
    useState<Record<string, string>>(initializeErrors());
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [userType, setUserType] = useState<string | null>(null);
  const [rolesIglesia, setRolesIglesia] = useState<string[]>([]);
  const optionsAdmins = [UserRoles.ADMIN, UserRoles.SUPER_ADMIN];
  const [userExists, setUserExists] = useState(false);
  const [userFetched, setUserFetched] = useState<any>(null);
  const [iglesiaContext, setIglesiaContext] = useState<any>(null);
  const [iglesiasOfUserFetched, setIglesiasOfUserFetched] = useState<any[]>([]);
  const [iglesiaOfUserFetchedSelected, setIglesiaOfUserFetchedSelected] = useState<any>(null);
  const [bloquedRolIglesiaInput, setBloquedRolIglesiaInput] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setIglesiaContext(authService.getIglesiaSelected().iglesia);
  }, []);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const response: any = await iglesiasService.uploadImage(
        Urls.IGLESIA_UPLOAD_IMAGE,
        file,
      );
      if (!!response) {
        try {
          const imageUrl: any = URL.createObjectURL(file);
          setImageUrl(response);
          setImagePreview(imageUrl);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const registerUser = async (formState: any) => {
    try {
      await authService.register(formState);
    } catch (error) {
      console.log(Register.REGISTRATION_FAILED);
    }
  };

  const {
    mutate: register,
    isLoading,
    isError,
    error,
  } = useMutation(registerUser);

  const validateForm = () => {
    const newErrors = validateFormUtils(
      fullName,
      lastName,
      password,
      confirmPassword,
      document,
      email,
      phone,
      birthDate,
    );
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value === "");
  };

  const handleOnChange = (event: React.ChangeEvent<{}>, value: any | null) => {
    const selectedChurch = iglesias.find(
      (iglesia: any) => iglesia.denomination === value?.denomination,
    );
    if (userExists) {
      setIglesiaOfUserFetchedSelected(selectedChurch);
      setRolesIglesia(
        userFetched.rolesIglesia
          .find((rol: any) => rol.iglesia?.id === selectedChurch?.id_igle)?.roles || [],
      );
    } else {
      setChurch(selectedChurch || null);
    }
  };

  const getIglesiaLabel = ({ id_igle, denomination }: any) =>
    `${denomination ?? ""} - ${id_igle ?? ""}`;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (iglesiaOfUserFetchedSelected && rolesIglesia.length === 0) {
      addNotification(
        queryClient,
        "error",
        "Debe seleccionar al menos un rol para la iglesia del usuario.",
      );
      return;
    }

    if (userExists) {
      if (isAdmin) {
        const updateState = createFormStateInRegisterForUserFetched(
          document,
          documentType,
          iglesiaOfUserFetchedSelected?.id_igle,
          rolesIglesia,
        );

        usersService.updateRolesUser(updateState).then(() => {
          addNotification(queryClient, "success", "Roles del usuario actualizados correctamente.");
        })
          .catch(() => {
            addNotification(queryClient, "error", "Error al actualizar usuario.");
          });
      } else {
        const updateState = createFormStateInRegisterForUserFetched(
          document,
          documentType,
          authService.getIglesiaSelected().iglesia.id,
          rolesIglesia,
        );

        usersService.updateRolesUser(updateState).then(() => {
          addNotification(queryClient, "success", "Roles del usuario actualizados correctamente.");
        })
          .catch(() => {
            addNotification(queryClient, "error", "Error al actualizar usuario.");
          });
      }

      setChurch(null);
      setFullName("");
      setLastName("");
      setDocument("");
      setDocumentType("");

      return;
    }

    const isValid = validateForm();

    if (!isValid) {
      return;
    } else {
      const formState = createFormStateInRegister(
        email,
        fullName,
        lastName,
        password,
        phone,
        birthDate,
        document,
        documentType,
        confirmPassword,
        isAdmin,
        authService,
        church,
        imageUrl,
        userType,
        rolesIglesia,
      );
      register(formState);
    }

    setChurch(null);
    setBirthDate(null);
    setFullName("");
    setLastName("");
    setDocument("");
    setDocumentType("");
    setEmail("");
    setBirthDate("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setUserType(null);
  };

  const handleUserTypeOnChange = (event: any, newValue: string | null) => {
    setUserType(newValue);
  };

  const handleFetchPerson = async () => {
    if (!document || !documentType) return;

    try {
      const response: any = await peopleService.findPeople(document, documentType);
      if (response) {
        setUserExists(true);
        setUserFetched(response.usuario);
        setFullName(response.name);
        setLastName(response.surname);
        const matchingRoles = response.usuario.rolesIglesia
          .find((rol: any) => rol.iglesia?.id === iglesiaContext?.id)?.roles || [];

        if (!isAdmin) {
          const message = matchingRoles.length > 0
            ? "El usuario ya existe, puede modificar sus roles en la iglesia de contexto."
            : "El usuario ya existe.";

          addNotification(queryClient, "info", message);

          if (matchingRoles.length === 0) {
            setBloquedRolIglesiaInput(true);
          } else {
            setRolesIglesia(matchingRoles);
          }
        } else if (matchingRoles.length > 0) {
          addNotification(queryClient, "info", "El usuario ya existe, puede modificar sus roles en la iglesias a la que pertecene.");
        }

        setIglesiasOfUserFetched(response.usuario.rolesIglesia.map((rol: any) => rol.iglesia));
      } else {
        setUserExists(false);
        setBloquedRolIglesiaInput(false);
      }
    } catch (error) {
      console.error("Error fetching person:", error);
      setUserExists(false);
      setBloquedRolIglesiaInput(false);
    }
  };

  const handleRolesChange = (event: any, value: any) => {
    if (value === null) {
      setRolesIglesia([]);
      setIglesiaOfUserFetchedSelected(null);
      return;
    }

    if (value.includes(UserRoles.DISABLED)) {
      setOpenModal(true);
    } else {
      setRolesIglesia(value);
    }
  };

  const handleConfirmChange = () => {
    setRolesIglesia([UserRoles.DISABLED]);
    setOpenModal(false);
  };

  return (
    <Box sx={stylesRegisterForm.box1}>
      <Box sx={stylesRegisterForm.box2}>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={stylesRegisterForm.typography1}>
          {RegisterFormEnum.REGISTRO_CURAS_PARROQUOS}
        </Typography>
      </Box>
      <Paper sx={stylesRegisterForm.paper}>
        <Typography sx={stylesRegisterForm.typography2}>
          {RegisterFormEnum.REGISTRO_CURAS_PARROQUOS}
        </Typography>
        <form onSubmit={handleSubmit} style={stylesRegisterForm.form}>
          <Typography
            variant={RegisterFormEnum.SUBTITLE1}
            sx={stylesRegisterForm.typography3}
          >
            {DatosPersonales.DATOS_PERSONALES}
          </Typography>

          <Grid container spacing={2} sx={stylesRegisterForm.grid}>
            <Grid item xs={12}>
              <div style={stylesRegisterForm.div}>
                <Button
                  variant={CONTAINED}
                  component={LABEL}
                  style={stylesRegister(imagePreview).button}
                >
                  Subir Foto
                  <input type={FILE.FILE} hidden onChange={handleFileChange} />
                </Button>
              </div>
              <TextField
                id={DataPersonal.FULL_NAME}
                label={DatosPersonales.NAME}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                autoComplete={State.OFF}
                fullWidth
                error={!!errors.nombre}
                helperText={errors.nombre}
                disabled={userExists}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.LAST_NAME}
                label={DatosPersonales.SURNAME}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                autoComplete={State.OFF}
                fullWidth
                error={!!errors.apellido}
                helperText={errors.apellido}
                disabled={userExists}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.DOCUMENT}
                label={DatosPersonales.DOCUMENT}
                type={DataPersonal.NUMBER}
                value={document}
                onChange={(e) => setDocument(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                autoComplete={State.OFF}
                fullWidth
                error={!!errors.documento}
                helperText={errors.documento}
                onBlur={() => handleFetchPerson()}
              />
            </Grid>
            <Grid item xs={6}>
              <DocumentTypeComponent
                id={DataPersonal.DOCUMENT_TYPE}
                InputLabelProps={{ sx: inputLabelStyles }}
                label={RegisterFormEnum.TIPO_DOCUMENTO}
                countries={getDocumentTypes()}
                value={documentType}
                onChange={(key: any, value: any) => {
                  setDocumentType(value.id);
                }}
                size={Sizes.SMALL}
                errors={!!errors.document_type}
                helperText={errors.document_type}
                onBlur={() => handleFetchPerson()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.EMAIL}
                label={DatosPersonales.EMAIL}
                type={TEXT}
                autoComplete={State.OFF}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.email}
                helperText={
                  <EmailValidation
                    value={email}
                    onChange={setEmail}
                    showError={!!errors.email}
                  />
                }
                disabled={userExists}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.BIRTHDATE}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                autoComplete={State.OFF}
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.fechaNacimiento}
                helperText={errors.fechaNacimiento}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={userExists}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.PHONE}
                label={DatosPersonales.TELEFONO}
                type={DataPersonal.NUMBER}
                autoComplete={State.OFF}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.telefono}
                helperText={errors.telefono}
                disabled={userExists}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.PASSWORD}
                label={DatosPersonales.CONTRASENA}
                type={DataPersonal.PASSWORD}
                autoComplete={NEW_PASSWORD}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.contraseña}
                helperText={errors.contraseña}
                disabled={userExists}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={CONFIRM_PASSWORD}
                label={CONFIRMAR_CONTRASENA}
                type={DataPersonal.PASSWORD}
                autoComplete={State.OFF}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.confirmarContraseña}
                helperText={errors.confirmarContraseña}
                disabled={userExists}
              />
            </Grid>
            {!!isAdmin && (
              <Grid item xs={12} style={stylesRegisterForm.grid1}>
                <Typography
                  variant={RegisterFormEnum.SUBTITLE1}
                  sx={stylesRegisterForm.typography4}
                >
                  {RegisterFormEnum.PARROQUIA_DONDE_COLABORA}
                </Typography>
                <Autocomplete
                  disabled={userExists}
                  id={RegisterFormEnum.CHURCH_ID}
                  options={iglesias}
                  getOptionLabel={getIglesiaLabel}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  value={church}
                  onChange={handleOnChange}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <span>{option.denomination}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={IGLESIA}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: State.OFF,
                      }}
                      value={church ? church.denomination : ""}
                      error={!!errors.iglesias}
                      helperText={errors.iglesias}
                    />
                  )}
                  size={Sizes.SMALL}
                  fullWidth
                />
              </Grid>
            )}
            {!!isAdmin && (
              <Grid item xs={12} style={stylesRegisterForm.grid2}>
                <Typography
                  variant={RegisterFormEnum.SUBTITLE1}
                  sx={stylesRegisterForm.typography5}
                >
                  {RegisterFormEnum.ROL_SISTEMA}
                </Typography>
                <Autocomplete
                  disabled={userExists}
                  id={USER_TYPE}
                  options={optionsAdmins}
                  getOptionLabel={(option) => option}
                  value={userType}
                  onChange={handleUserTypeOnChange}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <span>{option}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={RegisterFormEnum.ROL_SISTEMA}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: State.OFF,
                      }}
                      value={userType}
                      error={!!errors.userType}
                      helperText={errors.userType}
                    />
                  )}
                  size={Sizes.SMALL}
                  fullWidth
                />
              </Grid>
            )}
            {
              (isAdmin && userExists) &&
              <Grid item xs={12} style={stylesRegisterForm.grid1}>
                <Typography
                  variant={RegisterFormEnum.SUBTITLE1}
                  sx={stylesRegisterForm.typography4}
                >
                  {RegisterFormEnum.PARROQUIAS_DEL_USUARIO}
                </Typography>
                <Autocomplete
                  id={RegisterFormEnum.CHURCH_ID}
                  options={iglesiasOfUserFetched}
                  getOptionLabel={getIglesiaLabel}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  value={iglesiaOfUserFetchedSelected}
                  onChange={handleOnChange}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <span>{option.denomination}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={IGLESIA}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: State.OFF,
                      }}
                      value={church ? church.denomination : ""}
                      error={!!errors.iglesias}
                      helperText={errors.iglesias}
                    />
                  )}
                  size={Sizes.SMALL}
                  fullWidth
                />
              </Grid>
            }
            <Grid item xs={12} style={stylesRegisterForm.grid3}>
              <Typography
                variant={RegisterFormEnum.SUBTITLE1}
                sx={stylesRegisterForm.typography6}
              >
                {RegisterFormEnum.ROL_IGLESIA}
              </Typography>
              <Autocomplete
                disabled={bloquedRolIglesiaInput || (!iglesiaOfUserFetchedSelected && userExists && isAdmin)}
                multiple
                fullWidth
                value={rolesIglesia}
                options={[
                  UserRoles.SACERDOTE,
                  UserRoles.ADMINISTRATOR,
                  UserRoles.SECRETARIA,
                  UserRoles.COORDINADOR,
                  UserRoles.DISABLED,
                  UserRoles.SACERDOTE_INVITADO,
                ]}
                onChange={handleRolesChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={RegisterFormEnum.ROL_IGLESIA}
                    placeholder={RegisterFormEnum.SELECCIONE}
                  />
                )}
              />
            </Grid>
          </Grid>
          <RolesChangeModal open={openModal} onClose={() => setOpenModal(false)} onConfirm={handleConfirmChange} />
          <Button
            variant={CONTAINED}
            color={InOrder.PRIMARY}
            type={FormTextSend.SUBMIT}
            sx={stylesRegisterForm.button}
            fullWidth
          >
            {userExists ? Updated.UPDATED : Register.REGISTRARSE}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default RegisterForm;
