import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GenericTable from "../../../components/genericTable/GenericTable";
import ObservableService, {
  ObservableEvents,
} from "../../../services/ObservableService";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../../utils/RoutesEnum";
import { stylesListadoFeriado } from "./ListadoFeriadosStyles";
import {
  CreateFeriadoEnum,
  FiltrosEnum,
} from "../../../enums/pages/CreateFeriadoEnum";
import feriadosService from "../../../services/feriados/index";
import { isSuperAdmin } from "../../../utils/auth";
import CheckIcon from "@mui/icons-material/Check";
import { addNotification } from "../../../utils/notifications";
import { queryClient } from "../../../App";
import {
  feriadosColumns,
  fetchFeriados,
  scopeOptionsInitialize,
  stateOptionsInitialize,
} from "../../../utils/pages/CreateFeriadoUtils";
import { Sizes } from "../../../enums/components/componentsEnum";
import SelectComponent from "../../../components/autocomplete/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { updateFilter } from "../../../redux/FeriadosSlice";
import { RootState } from "../../../redux/store";

const FeriadosProgramados = () => {
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.feriado.filters);
  const [place, setPlace] = useState("");

  const handleFilterChange = React.useCallback(
    (key: string, value: any) => {
      const currentFilterValue = filters.find(
        (filter: any) => filter.param === key,
      )?.value;
      if (value && value.id !== undefined && value.id !== currentFilterValue) {
        dispatch(updateFilter({ param: key, value: value.id }));
      }
    },
    [filters, dispatch],
  );

  const scopeOptions = React.useMemo(() => scopeOptionsInitialize, []);
  const stateOptions = React.useMemo(() => stateOptionsInitialize, []);
  const scopeValue = React.useMemo(
    () =>
      filters.find((filter: any) => filter.param === FiltrosEnum.SCOPE)
        ?.value || "",
    [filters],
  );
  const stateValue = React.useMemo(
    () =>
      filters.find((filter: any) => filter.param === FiltrosEnum.STATE)
        ?.value || "",
    [filters],
  );

  const renderFilters = React.useCallback(
    () => (
      <Grid
        item
        container
        xs={12}
        gap={2}
        style={stylesListadoFeriado.gridRenderFilters}
      >
        <Grid item xs={1}>
          <button
            style={stylesListadoFeriado.gridButtonRender}
            onClick={() => navigate(-1)}
          >
            {CreateFeriadoEnum.BACK}
          </button>
        </Grid>
        <Grid item xs={2}>
          <SelectComponent
            id={FiltrosEnum.STATE}
            label={FiltrosEnum.ESTADO}
            options={stateOptions}
            value={stateValue}
            onChange={(event: any, newValue: any) => {
              handleFilterChange(FiltrosEnum.STATE, newValue);
            }}
            onClear={() =>
              handleFilterChange(FiltrosEnum.STATE, stateOptions[0])
            }
            size={Sizes.SMALL}
            errors={false}
            helperText={""}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectComponent
            id={FiltrosEnum.SCOPE}
            label={FiltrosEnum.ALCANCE}
            options={scopeOptions}
            value={scopeValue}
            onChange={(event: any, newValue: any) => {
              handleFilterChange(FiltrosEnum.SCOPE, newValue);
            }}
            onClear={() =>
              handleFilterChange(FiltrosEnum.SCOPE, scopeOptions[0])
            }
            size={Sizes.SMALL}
            errors={false}
            helperText={""}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id={FiltrosEnum.PLACE}
            label={FiltrosEnum.LUGAR}
            value={place}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPlace(event.target.value);
              dispatch(
                updateFilter({
                  param: FiltrosEnum.PLACE,
                  value: event.target.value,
                }),
              );
            }}
            size={Sizes.SMALL}
            error={false}
            helperText={""}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id={FiltrosEnum.DATE}
            label={FiltrosEnum.FECHA}
            type={FiltrosEnum.DATE}
            value={
              filters.find((filter: any) => filter.param === FiltrosEnum.DATE)
                ?.value || ""
            }
            onChange={(event) => {
              handleFilterChange(FiltrosEnum.DATE, { id: event.target.value });
            }}
            size={Sizes.SMALL}
            error={false}
            helperText={""}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            style={stylesListadoFeriado.button}
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(RoutesEnum.FERIADOS_CREATE);
            }}
          >
            {CreateFeriadoEnum.CREAR_FERIADO}
          </Button>
        </Grid>
      </Grid>
    ),
    [filters, handleFilterChange],
  );

  const handleAprovedFeriado = async (id: number) => {
    try {
      const feriado = await feriadosService.aprovedFeriado(id);
      addNotification(
        queryClient,
        "success",
        CreateFeriadoEnum.FERIADO_APROBADO,
      );
      setForceRefresh(new Date());
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error al aprobar el feriado:", error.message);
      } else {
        addNotification(
          queryClient,
          "error",
          CreateFeriadoEnum.FERIADO_NO_APROBADO,
        );
      }
    }
  };

  const feriadosActions = [
    {
      icon: (row: any) =>
        isSuperAdmin() && !row.aproved ? <CheckIcon /> : <></>,
      onClick: (row: any) => {
        handleAprovedFeriado(row.id);
      },
    },
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {
        const url = `/panel/feriados/edit/${row.id}`;
        navigate(url, { state: { feriadoId: row.id } });
      },
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar esta programación?",
        );
        if (confirmDelete) {
          feriadosService
            .deleteFeriado(row.id)
            .then((response: any) => {
              setForceRefresh(new Date());
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "success",
                message: "Se elimino el turno correctamente",
              });
            })
            .catch((error: any) => {});
        }
      },
    },
  ];

  return (
    <GenericTable<any>
      filtersData={filters}
      columns={feriadosColumns}
      refresh={forceRefresh}
      actions={feriadosActions}
      title="Feriados"
      fetchData={fetchFeriados}
      filters={renderFilters}
    />
  );
};

const ListadoDeFeriadosProgramados = () => {
  return (
    <Box sx={stylesListadoFeriado.box1}>
      <Box sx={stylesListadoFeriado.box2}>
        <Grid container style={stylesListadoFeriado.grid}>
          <Grid item xs={9}></Grid>
          <FeriadosProgramados />
        </Grid>
      </Box>
    </Box>
  );
};

export default ListadoDeFeriadosProgramados;
