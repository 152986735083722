import { Box, Dialog, Typography, CircularProgress } from "@mui/material";
import authService from "../../services/auth";
import { useEffect, useState } from "react";
import ProfilePage from "../Profile/ProfilePage";
import catedral from "../../assets/panel/catedra2.jpg";
import { UserRoles } from "../../enums/components/componentsEnum";
import DisabledUserModal from "../../components/disabledUserModal/DisabledUserModal";
import iglesiasService from "../../services/iglesias";

const Panel = () => {
  const [userData, setUserData] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isOnlyForChangeIglesia, setIsOnlyForChangeIglesia] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const iglesiaSelected = authService.getIglesiaSelected().iglesia;
  const [imageOfIglesia, setImageOfIglesia] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  useEffect(() => {
    const { nombre, apellido } = authService.getUserData();
    setUserData(nombre + " " + apellido);
  }, []);

  useEffect(() => {
    if (!iglesiaSelected?.id) return;
  
    const cachedImage = sessionStorage.getItem(`iglesia_image_${iglesiaSelected.id}`);

    if (cachedImage) {
      setImageOfIglesia(cachedImage);
      setLoadingImage(false);
      return;
    }
  
    setLoadingImage(true);
  
    iglesiasService.getImageByIdIgle(iglesiaSelected.id)
      .then((imageUrl: string) => {
        const updatedImageUrl = `${imageUrl}?t=${new Date().getTime()}`;
        
        sessionStorage.setItem(`iglesia_image_${iglesiaSelected.id}`, updatedImageUrl);
  
        setImageOfIglesia(updatedImageUrl);
      })
      .catch(error => console.error('Error setting image:', error))
      .finally(() => setLoadingImage(false));
  }, [iglesiaSelected.id]);

  useEffect(() => {
    const user = authService.getUserData();

    const rolesEnIglesia = user.rolesIglesia.find(
      (rolIglesia: any) => rolIglesia.iglesia.id === iglesiaSelected.id
    );

    if (rolesEnIglesia?.roles?.includes(UserRoles.DISABLED)) {
      console.error("[AUTH_LOG] Usuario está deshabilitado en esta iglesia");
      setOpenModal(true);
    }

  }, [iglesiaSelected]);

  const onOpenChangeIglesia = () => {
    setIsOnlyForChangeIglesia(true);
    setDialogOpen(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        position: "relative",
        backgroundImage: loadingImage
          ? "none"
          : `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${imageOfIglesia || catedral})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loadingImage && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={60} sx={{ color: "#3A8DBE" }} />
        </Box>
      )}

      <Box sx={{ padding: "6%" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "white",
            paddingTop: "0px",
          }}
        >
          {authService.getIglesiaSelected()?.iglesia?.denomination}
        </Typography>
        <Typography
          onClick={() => {
            setDialogOpen(true);
          }}
          sx={{
            fontSize: "18px",
            cursor: "pointer",
            fontWeight: 400,
            color: "white",
            paddingTop: "10px",
          }}
        >
          {userData}
        </Typography>
        <Dialog
          open={dialogOpen}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            height: "94%",
            backgroundColor: "transparent !important",
          }}
        >
          <ProfilePage
            isOwner
            custom={{
              fullScreen: "100%",
              backgroundColor: "transparent",
              handleGoBack: () => {
                setDialogOpen(false);
                setIsOnlyForChangeIglesia(false);
              },
            }}
            isOnlyForChangeIglesia={isOnlyForChangeIglesia}
          />
        </Dialog>
      </Box>
      <DisabledUserModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onOpenChangeIglesia={onOpenChangeIglesia}
      />
    </Box>
  );
};

export default Panel;
