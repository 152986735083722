import React, { useRef, useState } from "react";
import { Box, Grid, Button, Typography, IconButton } from "@mui/material";
import {
  AddPeopleToTurnTexts,
  AddToPeopleStrings,
  CreateActividadParroquialEnum,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import { useNavigate, useParams } from "react-router-dom";
import {
  DOCUMENT,
  Errors,
  FormTextSend,
  Locaciones,
} from "../../../enums/components/componentsEnum";
import { LoadState } from "../../../enums/LoadStateEnum";
import peopleServie from "../../../services/people";
import {
  assignResponseToParticipante,
  formatParticipanteToTurnData,
  initialParticipantToTurn,
} from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";
import {
  BirthPlaces,
  IglesiaDirection,
  ParticipantToTurn,
} from "../InterfacesActividadParroquial";
import { queryClient } from "../../../App";
import { addNotification } from "../../../utils/notifications";
import { inputLabelStyles } from "../PeopleActividadParroquial/PeopleActividadParroquialStyles";
import DatosNinio from "../PeopleActividadParroquial/DatosNinio";
import { AddPeopleToTurnStyles } from "./AddPeopleToTurnStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatosPadre from "../PeopleActividadParroquial/DatosPadre";
import DatosMadre from "../PeopleActividadParroquial/DatosMadre";
import DatosPadrinos from "../PeopleActividadParroquial/DatosPadrinos";
import turnosHabilitadosService from "../../../services/turnosHabilitados/TurnosHabilitadosService";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";
import DatosIglesia from "../PeopleActividadParroquial/DatosIglesia";
import { locacionesService } from "../../../services/locaciones";
import { useQuery } from "react-query";

const AddPeopleToTurn: React.FC = () => {
  const navigate = useNavigate();
  const [_forceRender, setForceRender] = useState<Date>();
  const { id, type, coordinatorId } = useParams<{
    id: string;
    type: SacramentoEnum;
    coordinatorId: any;
  }>();
  const [isFetch, setIsFetch] = useState(false);
  const loadState = useRef(LoadState.DEFAULT);
  const [birthNinioPlaces, setBirthNinioPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  const [birthFatherPlaces, setBirthFatherPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  const [birthMotherPlaces, setBirthMotherPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  let [participante, setParticipante] = useState<ParticipantToTurn>(
    initialParticipantToTurn(),
  );
  const [disabledParticipant, setDisabledParticipant] = useState(false);
  const [disabledFather, setDisabledFather] = useState(false);
  const [disabledMother, setDisabledMother] = useState(false);
  const [disabledGodparent, _setDisabledGodparent] = useState(false);
  const [disabledChurch, setDisabledChurch] = useState(false);
  const [iglesias, setIglesias] = useState<any[]>([]);
  const [iglesiaProvinces, setIglesiaProvinces] = useState<any[]>([]);
  const [iglesiaParts, setIglesiaParts] = useState<any[]>([]);
  const [iglesiaDirection, setIglesiaDirection] = useState<IglesiaDirection>({
    iglesia: null,
    part: null,
    province: null,
    country: null,
  });
  const {
    data: countries,
    isLoading: _locacionesDataIsLoading,
    error: _locacionesDataError,
  } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
    locacionesService.getCountries(),
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  const resetStateValidator = (
    state: LoadState = LoadState.DEFAULT,
    isFetch = false,
  ) => {
    setIsFetch(isFetch);
    loadState.current = state;
    setForceRender(new Date());
  };

  const handleFetchPerson = async (
    dni: string,
    documentType: string,
    typePeople: any,
  ) => {
    dni = dni ?? "";
    documentType = documentType ?? "";
    if (!!dni && dni.length > 0 && documentType.length > 0) {
      peopleServie
        .findPeople(dni, documentType)
        .then(async (response: any) => {
          if (!!response) {
            let updatedParticipante: ParticipantToTurn;
            switch (typePeople) {
              case "ninio":
                let Sacramento: any;
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                );

                let updatedParticipant = updatedParticipante?.ninio;
                Sacramento = response.sacramentos?.find(
                  (sacramento: any) => sacramento.sacramento.type === SacramentoEnum.Bautismo,
                );

                const iglesiaData = {
                  idIgle: Sacramento?.sacramento?.iglesia?.id || null,
                  book: Sacramento?.sacramento?.book || "",
                  folio: Sacramento?.sacramento?.folio || "",
                  dateBaptism: Sacramento?.sacramento?.startDate || "",
                };

                setBirthNinioPlaces({
                  nationality:
                    response?.locality?.part?.province?.country ||
                    response?.nationality,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });
                setBirthMotherPlaces({
                  nationality:
                    response?.mother?.locality?.part?.province?.country ||
                    response?.mother?.nationality,
                  birthProvince: response?.mother?.locality?.part?.province,
                  birthPart: response?.mother?.locality?.part,
                  birthLocality: response?.mother?.locality,
                });
                setBirthFatherPlaces({
                  nationality:
                    response?.father?.locality?.part?.province?.country ||
                    response?.father?.nationality,
                  birthProvince: response?.father?.locality?.part?.province,
                  birthPart: response?.father?.locality?.part,
                  birthLocality: response?.father?.locality,
                });

                setIglesiaDirection({
                  iglesia: Sacramento?.sacramento?.iglesia,
                  part: Sacramento?.sacramento?.iglesia?.locality?.part,
                  province:
                    Sacramento?.sacramento?.iglesia?.locality?.part?.province,
                  country:
                    Sacramento?.sacramento?.iglesia?.locality?.part?.province
                      ?.country,
                });

                setParticipante((prev: any) => ({
                  ...prev,
                  ninio: updatedParticipant,
                  iglesia: iglesiaData,
                }));

                if (Sacramento?.sacramento?.iglesia?.id) {
                  setDisabledChurch(true);
                }

                setDisabledParticipant(true);
                resetStateValidator(LoadState.LOADED, true);

                setParticipante((prev) => ({
                  ...prev,
                  ninio: updatedParticipante?.ninio,
                  father: updatedParticipante?.father || prev.father,
                  mother: updatedParticipante?.mother || prev.mother,
                  iglesia: iglesiaData,
                }));

                updatedParticipante.father.document !== "" &&
                  setDisabledFather(true);
                updatedParticipante.mother.document !== "" &&
                  setDisabledMother(true);

                break;
              case "father":
                console.log("Entre a father");
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                );
                setParticipante((prev) => ({
                  ...prev,
                  father: updatedParticipante.father,
                }));
                setBirthFatherPlaces({
                  nationality:
                    response?.locality?.part?.province?.country ||
                    response?.nationality,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });
                break;
              case "mother":
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                );
                setParticipante((prev) => ({
                  ...prev,
                  mother: updatedParticipante.mother,
                }));
                setBirthMotherPlaces({
                  nationality:
                    response?.locality?.part?.province?.country ||
                    response?.nationality,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });
                break;
              case "godparent":
                updatedParticipante = assignResponseToParticipante(
                  participante,
                  response,
                  typePeople,
                );
                setParticipante((prev) => ({
                  ...prev,
                  godparent: updatedParticipante.godparent,
                }));
                break;
              default:
                throw new Error(`Unknown typePeople: ${typePeople}`);
            }

            resetStateValidator(LoadState.LOADED, true);
          } else {
            resetStateValidator();
          }
        })
        .catch((error: any) => {
          resetStateValidator();
        });
    } else {
      resetStateValidator();
    }
  };

  const handleFormChange = (key: any, value: any) => {
    const keys = key.split(".");
    const isDocumentType = keys[keys.length - 1] === "document_type";

    if (keys.length === 1) {
      // Upload on top level
      setParticipante((prev) => ({
        ...prev,
        [keys[0]]: isDocumentType ? value.id : value,
      }));
    } else if (keys.length === 2) {
      // Upload on nested level
      const [parentKey, childKey] = keys as [
        keyof ParticipantToTurn,
        keyof ParticipantToTurn,
      ];
      setParticipante((prev) => {
        const parentValue = prev[parentKey];
        if (typeof parentValue === "object" && parentValue !== null) {
          return {
            ...prev,
            [parentKey]: {
              ...parentValue,
              [childKey!]: isDocumentType ? value.id : value,
            },
          };
        } else {
          return prev;
        }
      });
    }
  };

  const handleCountryChange = (key: any, value: any, type: string) => {
    if (key === DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findProvincesByCountryId(value.id)
      .then((response: any) => {
        if (type === "iglesia") {
          setIglesiaProvinces(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PROVINCES, error);
      });
  };

  const handleProvinceChange = (key: any, value: any, type: string) => {
    if (key === DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findPartsByProvinceId(value.id)
      .then((response: any) => {
        if (type === "iglesia") {
          setIglesiaParts(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PARTS, error);
      });
  };

  const handlePartChange = (key: any, value: any, type: string) => {
    if (key === DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    locacionesService.findPartWithLocalities(value.id).then((response: any) => {
      setIglesias(
        response.localities.flatMap((locality: any) => {
          const localityName = locality.name;
          return locality.iglesias.map((iglesia: any) => {
            iglesia.name = `${iglesia.denomination}-(${localityName})`;
            return iglesia;
          });
        }),
      );
    });
  };


  const onChangeBirthNinioPlaces = (key: string, value: any) => {
    if (key === "nationality") {
      setParticipante((prev) => ({
        ...prev,
        ninio: {
          ...prev.ninio,
          nationality: value.id,
        },
      }));
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "birthProvince") {
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        ninio: {
          ...prev.ninio,
          birthLocality: value.id,
        },
      }));
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "birthPart") {
      setBirthNinioPlaces((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const onChangeBirthFatherPlaces = (key: string, value: any) => {
    if (key === "nationality") {
      setParticipante((prev) => ({
        ...prev,
        father: {
          ...prev.father,
          nationality: value.id,
        },
      }));
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthProvince") {
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthPart") {
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        father: {
          ...prev.father,
          birthLocality: value.id,
        },
      }));
      setBirthFatherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const onChangeBirthMotherPlaces = (key: string, value: any) => {
    if (key === "nationality") {
      setParticipante((prev) => ({
        ...prev,
        mother: {
          ...prev.mother,
          nationality: value.id,
        },
      }));
    } else if (key === "birthProvince") {
      setBirthMotherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthPart") {
      setBirthMotherPlaces((prev) => ({
        ...prev,
        [key]: value
      }));
    } else if (key === "birthLocality") {
      setParticipante((prev) => ({
        ...prev,
        mother: {
          ...prev.mother,
          birthLocality: value.id,
        },
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidParticipantLocation()) {
      addNotification(
        queryClient,
        "error",
        "Debe completar la localización del participante. Opciones válidas: dejar en blanco, seleccionar solo el país, o completar todos los campos (País, Provincia, Partido, Localidad)."
      );

      return;
    }

    const formattedData = formatParticipanteToTurnData(
      participante,
      type,
      coordinatorId,
    );

    turnosHabilitadosService
      .addPeopleToTurn(formattedData, id)
      .then(() => {
        addNotification(
          queryClient,
          "success",
          AddPeopleToTurnTexts.PARTICIPANTE_AÑADIDO_CORRECTAMENTE,
        );
      })
      .catch((error: any) => {
        let errorMessage =
          error?.response?.data?.message ||
          error?.response?.data?.errorCode ||
          AddPeopleToTurnTexts.ERROR_AÑADIR_PARTICIPANTE;

        const expectedErrorPattern = /^El participante ya tiene un sacramento del tipo "(Comunion|Confirmacion)" y no puede ser agregado nuevamente\.$/;

        if (
          error?.response?.status === 400 &&
          expectedErrorPattern.test(error?.response?.data?.message)
        ) {
          errorMessage = error.response.data.message;
        }

        addNotification(queryClient, "error", errorMessage);
      });
  };

  const isValidParticipantLocation = () => {
    // Define an array of participants along with their respective location data
    const participants = [
      { data: participante.ninio, places: birthNinioPlaces },
      { data: participante.mother, places: birthMotherPlaces },
      { data: participante.father, places: birthFatherPlaces },
    ].filter(p => p.data); // Remove null or undefined participants

    // If there are no participants, the validation passes
    if (participants.length === 0) return true;

    // Check if every participant meets one of the allowed conditions
    return participants.every(({ data, places }) => {

      const { nationality, birthProvince, birthPart, birthLocality } = places;

      // Option 1: All fields are empty
      const isEmpty = !nationality && !birthProvince && !birthPart && !birthLocality;

      // Option 2: Only country (nationality) is selected
      const hasOnlyCountry = nationality && !birthProvince && !birthPart && !birthLocality;

      // Option 3: All location fields are fully completed
      const hasAllFields = nationality && birthProvince && birthPart && birthLocality;

      return isEmpty || hasOnlyCountry || hasAllFields;
    });
  };

  return (
    <Box sx={AddPeopleToTurnStyles.divForm}>
      <form onSubmit={handleSubmit}>
        <Box sx={AddPeopleToTurnStyles.box2}>
          <IconButton onClick={handleGoBack} sx={{}}>
            <ArrowBackIcon />
          </IconButton>

          <Box sx={AddPeopleToTurnStyles.titleContainer}>
            <Typography sx={AddPeopleToTurnStyles.typographyTitle}>
              {AddToPeopleStrings.ADD_PEOPLE_TO_TURN}
            </Typography>
          </Box>
        </Box>
        <DatosNinio
          participante={participante}
          handleFormChange={handleFormChange}
          disabledNinio={disabledParticipant}
          inputLabelStyles={inputLabelStyles}
          handleFetchPerson={handleFetchPerson}
          resetStateValidator={resetStateValidator}
          setParticipante={setParticipante}
          birthPlaces={birthNinioPlaces}
          onChangeBirthPlaces={onChangeBirthNinioPlaces}
          isFetch={isFetch}
          loadState={loadState}
        />

        <DatosIglesia
          participante={participante}
          handleFormChange={handleFormChange}
          disabledChurch={disabledChurch}
          inputLabelStyles={inputLabelStyles}
          handleCountryChange={handleCountryChange}
          handleProvinceChange={handleProvinceChange}
          handlePartChange={handlePartChange}
          countries={countries}
          iglesiaProvinces={iglesiaProvinces}
          iglesiaParts={iglesiaParts}
          iglesias={iglesias}
          iglesiaDirection={iglesiaDirection}
          loadState={loadState}
          inAddPeopleToTurn={true}
        />

        <DatosPadre
          participante={participante}
          handleFormChange={handleFormChange}
          disabledFather={disabledFather}
          inputLabelStyles={inputLabelStyles}
          handleFetchPerson={handleFetchPerson}
          resetStateValidator={resetStateValidator}
          setParticipante={setParticipante}
          birthPlaces={birthFatherPlaces}
          onChangeBirthPlaces={onChangeBirthFatherPlaces}
          isFetch={isFetch}
          loadState={loadState}
        />

        <DatosMadre
          participante={participante}
          handleFormChange={handleFormChange}
          disabledMother={disabledMother}
          inputLabelStyles={inputLabelStyles}
          handleFetchPerson={handleFetchPerson}
          resetStateValidator={resetStateValidator}
          setParticipante={setParticipante}
          birthPlaces={birthMotherPlaces}
          onChangeBirthPlaces={onChangeBirthMotherPlaces}
          isFetch={isFetch}
          loadState={loadState}
        />

        <DatosPadrinos
          participante={participante}
          handleFormChange={handleFormChange}
          disabledGodparent={disabledGodparent}
          inputLabelStyles={inputLabelStyles}
          handleFetchPerson={handleFetchPerson}
          resetStateValidator={resetStateValidator}
          setParticipante={setParticipante}
        />

        <Grid sx={AddPeopleToTurnStyles.gridButton}>
          <Button
            type={FormTextSend.SUBMIT}
            variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
            color={CreateActividadParroquialEnum.PRIMARY}
            style={AddPeopleToTurnStyles.buttonSave}
          >
            {CreateActividadParroquialEnum.ADD_PARTICIPANT}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default AddPeopleToTurn;
