import authService from "../../services/auth/index";

const baseURL = process.env.REACT_APP_API_URL;

export const uploadImage = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("image", file);
  
    const token = localStorage.getItem("access_token");
    const iglesiaSelected = authService.getIglesiaSelected().iglesia;
  
    const response = await fetch(`${baseURL}/iglesia/upload-image/${iglesiaSelected?.id}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error("Error uploading image");
    }
  
    const data = await response.json();
    return data.imageUrl;
  };
  