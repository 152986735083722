import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Box,
  IconButton,
  Grid,
  Alert,
  Paper,
  Typography,
  Autocomplete,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { inputLabelStyles, styles } from "./SacerdoteShadowStyles";
import {
  CONFIRMAR_CONTRASENA,
  CONFIRM_PASSWORD,
  CONTAINED,
  Create_Sacerdote,
  DATE,
  DOCUMENT,
  DataPersonal,
  DatosPersonales,
  Errors,
  FILE,
  FormTextSend,
  IGLESIA,
  IGLESIA_CAPITALIZED,
  InOrder,
  LABEL,
  Locaciones,
  Locations,
  NACIMIENTO,
  NEW_PASSWORD,
  PersonalInfoId,
  RegisterFormEnum,
  Sizes,
  State,
  Status,
  TEXT,
  Urls,
  UserRoles,
} from "../../enums/components/componentsEnum";
import {
  createFormStateInRegisterUserSacerdote,
  getDocumentTypes,
  initializeErrorsUserSacerdote,
  validateFormUtils,
} from "../../utils/forms/formsUtils";
import LocationSelect from "../../components/autocomplete/countries/LocationSelect";
import { LoadState } from "../../enums/LoadStateEnum";
import { locacionesService } from "../../services/locaciones";
import { useQuery } from "react-query";
import DocumentTypeComponent from "../../components/autocomplete/documentType/DocumentTypeComponent";
import EmailValidation from "../../components/emailValidation";
import authService from "../../services/auth";
import { stylesRegister } from "../../components/forms/registerForm/RegisterFormStyles";
import iglesiasService from "../../services/iglesias";
import ArquidiocesisSelect from "../../components/arquidiocesis/arquidiocesisSelect";
import arquidiocesisService from "../../services/arquidiocesis";
import { Option } from "../../interfaces/components/interfacesAutoComplete";

const CreateUserSacerdote = () => {
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paisRecomendado, setPaisRecomendado] = useState("");
  const [ciudadRecomendada, setCiudadRecomendada] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const sacerdoteShadow = location.state.sacerdoteShadow;
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rolesIglesia, setRolesIglesia] = useState<string[]>([]);
  const loadState = useRef(LoadState.DEFAULT);
  const [iglesias, setIglesias] = useState<any[]>([]);
  const [iglesia, setIglesia] = useState(null);
  const [iglesiaProvinces, setIglesiaProvinces] = useState<any[]>([]);
  const [iglesiaParts, setIglesiaParts] = useState<any[]>([]);
  const [document, setDocument] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [idExcel, setIdExcel] = useState(null);
  const systemRols: any = [];
  const [arquidiocesisOptions, setArquidiocesisOptions] = useState<any[]>([]);
  const [arquidiocesisSelect, setArquidiocesisSelect] = useState<Option | null>(
    null,
  );
  const [errors, setErrors] = useState<Record<string, string>>(
    initializeErrorsUserSacerdote(),
  );
  const {
    data: countries,
    isLoading: locacionesDataIsLoading,
    error: locacionesDataError,
  } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
    locacionesService.getCountries(),
  );
  const [nacimientoProvinces, setNacimientoProvinces] = useState<any[]>([]);
  const [nacimientoParts, setNacimientoParts] = useState<any[]>([]);
  const [nacimientoLocalities, setNacimientoLocalities] = useState<any[]>([]);
  const [nacimientoCountry, setNacimientoCountry] = useState<any>(null);
  const [nacimientoPart, setNacimientoPart] = useState<any>(null);
  const [nacimientoProvince, setNacimientoProvince] = useState<any>(null);
  const [nacimientoLocality, setNacimientoLocality] = useState<any>(null);

  useEffect(() => {
    if (sacerdoteShadow) {
      if (sacerdoteShadow.localidadNacimiento) {
        locacionesService
          .findProvincePartAndCountryByLocalityId(
            sacerdoteShadow.localidadNacimiento.id,
          )
          .then((response: any) => {
            setNacimientoCountry(response.part.province.country);
            setNacimientoProvince(response.part.province);
            setNacimientoPart(response.part);
          })
          .catch((error: any) => {
            console.log(Errors.ERROR_GET_LOCACIONES, error);
          });
      }
      const arquidiocisSelectFormat = {
        id: sacerdoteShadow.arquidiocesis.id,
        name: `${sacerdoteShadow.denomination} (${sacerdoteShadow.abbreviation})`,
      };
      setFullName(sacerdoteShadow.nombre);
      setLastName(sacerdoteShadow.apellido);
      setArquidiocesisSelect(arquidiocisSelectFormat);
      setPaisRecomendado(sacerdoteShadow.pais);
      setCiudadRecomendada(sacerdoteShadow.ciudad);
      setIdExcel(sacerdoteShadow.idExcel);
      setBirthDate(sacerdoteShadow.fecha_nacimiento);
      setNacimientoLocality(sacerdoteShadow.localidadNacimiento);
    }
  }, [sacerdoteShadow]);

  useEffect(() => {
    arquidiocesisService
      .getAllArquidiocesis()
      .then((response: any) => {
        setArquidiocesisOptions(response);
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_ARQUIDIOCESIS, error);
      });
  }, []);

  const [roleSelected, setRoleSelected] = useState<any>([
    { id: 1, name: UserRoles.SACERDOTE },
    { id: 2, name: UserRoles.ADMINISTRATOR },
  ]);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const response: any = await iglesiasService.uploadImage(
        Urls.IGLESIA_UPLOAD_IMAGE,
        file,
      );
      if (!!response) {
        try {
          const imageUrl: any = URL.createObjectURL(file);
          setImageUrl(response);
          setImagePreview(imageUrl);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const registerUser = async (formState: any) => {
    await authService
      .register(formState)
      .then((response: any) => {
        setMessage("Se agrego sacerdote correctamente");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 10000);
      })
      .catch((error: any) => {
        setErrorMessage(error);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 10000);
      });
  };

  const {
    mutate: register,
    isLoading,
    isError,
    error,
  } = useMutation(registerUser);

  const validateForm = () => {
    const newErrors = validateFormUtils(
      fullName,
      lastName,
      password,
      confirmPassword,
      document,
      email,
      phone,
      birthDate,
    );
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value === "");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = validateForm();
    if (!isValid) {
      return;
    } else {
      const formState = createFormStateInRegisterUserSacerdote(
        id,
        email,
        fullName,
        lastName,
        password,
        phone,
        birthDate,
        document,
        documentType,
        confirmPassword,
        arquidiocesisSelect,
        authService,
        iglesia,
        imageUrl,
        systemRols,
        rolesIglesia,
        idExcel,
        nacimientoCountry,
        nacimientoProvince,
        nacimientoLocality,
      );
      register(formState);
    }
    setIglesia(null);
    setBirthDate(null);
    setFullName("");
    setLastName("");
    setDocument("");
    setDocumentType("");
    setEmail("");
    setBirthDate("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setArquidiocesisSelect(null);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCountryChange = (key: any, value: any, type: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findProvincesByCountryId(value.id)
      .then((response: any) => {
        if (type === "iglesia") {
          setIglesiaProvinces(response);
        } else {
          setNacimientoProvinces(response);
          setNacimientoCountry(value);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PROVINCES, error);
      });
  };

  const handleProvinceChange = (key: any, value: any, type: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findPartsByProvinceId(value.id)
      .then((response: any) => {
        if (type === "iglesia") {
          setIglesiaParts(response);
        } else {
          setNacimientoParts(response);
          setNacimientoProvince(value);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PARTS, error);
      });
  };

  const handlePartChange = (key: any, value: any, type: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findPartWithLocalities(value.id)
      .then((response: any) => {
        if (type === "nacimiento") {
          setNacimientoLocalities(response.localities);
        } else {
          setIglesias(
            response.localities.flatMap((locality: any) => {
              const localityName = locality.name;
              return locality.iglesias.map((iglesia: any) => {
                iglesia.name = `${iglesia.denomination}-(${localityName})`;
                return iglesia;
              });
            }),
          );
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR, error);
      });
  };

  const handleArquidiocesisChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    setArquidiocesisSelect(value);
  };

  return (
    <Box sx={styles.boxPrincipal}>
      <Box sx={styles.box1}>
        <Box sx={styles.box2}>
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={styles.typography1}>
            {Create_Sacerdote.CREAR_SACERDOTE_SISTEMA}
          </Typography>
          <div></div>
        </Box>
        <Paper sx={styles.paper}>
          <Typography sx={styles.typography2}>
            {RegisterFormEnum.REGISTRO_PARA_SACERDOTES}
          </Typography>
          <form onSubmit={handleSubmit} style={styles.form}>
            <Typography
              variant={RegisterFormEnum.SUBTITLE1}
              sx={styles.typography3}
            >
              {DatosPersonales.DATOS_PERSONALES}
            </Typography>

            <Grid container columnSpacing={2} sx={styles.renderContentGrid}>
              <Grid item xs={12}>
                <div style={styles.div}>
                  <Button
                    variant={CONTAINED}
                    component={LABEL}
                    style={stylesRegister(imagePreview).button}
                  >
                    Subir Foto
                    <input
                      type={FILE.FILE}
                      hidden
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.NAME}
                  label={DatosPersonales.NAME}
                  value={fullName || ""}
                  onChange={(e) => setFullName(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  autoComplete={State.OFF}
                  fullWidth
                  error={!!errors.nombre}
                  helperText={errors.nombre}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.SURNAME}
                  label={DatosPersonales.SURNAME}
                  value={lastName || ""}
                  onChange={(e) => setLastName(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  autoComplete={State.OFF}
                  fullWidth
                  error={!!errors.apellido}
                  helperText={errors.apellido}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentTypeComponent
                  id={DataPersonal.DOCUMENT_TYPE}
                  InputLabelProps={{ sx: inputLabelStyles }}
                  label={RegisterFormEnum.TIPO_DOCUMENTO}
                  countries={getDocumentTypes()}
                  value={documentType || ""}
                  onChange={(key: any, value: any) => {
                    setDocumentType(value.id);
                  }}
                  size={Sizes.SMALL}
                  errors={!!errors.document_type}
                  helperText={errors.document_type}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.DOCUMENT}
                  label={DatosPersonales.DOCUMENT}
                  type={DataPersonal.NUMBER}
                  value={document || ""}
                  onChange={(e) => setDocument(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  autoComplete={State.OFF}
                  fullWidth
                  error={!!errors.documento}
                  helperText={errors.documento}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.EMAIL}
                  label={DatosPersonales.EMAIL}
                  type={TEXT}
                  autoComplete={State.OFF}
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                  error={false}
                  helperText={
                    <EmailValidation
                      value={email}
                      onChange={setEmail}
                      showError={!!errors.email}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.BIRTHDATE}
                  label={DatosPersonales.FECHA_NACIMIENTO}
                  type={DATE}
                  autoComplete={State.OFF}
                  value={birthDate || ""}
                  onChange={(e) => setBirthDate(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                  error={!!errors.fecha_nacimiento}
                  helperText={errors.fecha_nacimiento}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.PASSWORD}
                  label={DatosPersonales.CONTRASENA}
                  type={DataPersonal.PASSWORD}
                  autoComplete={NEW_PASSWORD}
                  value={password || ""}
                  onChange={(e) => setPassword(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                  error={!!errors.contraseña}
                  helperText={errors.contraseña}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={CONFIRM_PASSWORD}
                  label={CONFIRMAR_CONTRASENA}
                  type={DataPersonal.PASSWORD}
                  autoComplete={State.OFF}
                  value={confirmPassword || ""}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                  error={!!errors.confirmarContraseña}
                  helperText={errors.confirmarContraseña}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.PHONE}
                  label={DatosPersonales.TELEFONO}
                  type={DataPersonal.NUMBER}
                  autoComplete={State.OFF}
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value)}
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                  error={!!errors.telefono}
                  helperText={errors.telefono}
                />
              </Grid>
              <Grid item xs={6}>
                <ArquidiocesisSelect
                  id={PersonalInfoId.ID_ARQUIDIOCESIS_INCARDINADO}
                  label={DatosPersonales.ID_ARQUIDIOCESIS_INCARDINADO}
                  options={arquidiocesisOptions ?? []}
                  disabled={loadState.current == LoadState.LOADED}
                  onChange={handleArquidiocesisChange}
                  size={Sizes.SMALL}
                  loadState={loadState}
                  errors={!!errors.arquidioseis_incardinado}
                  value={arquidiocesisSelect}
                />
              </Grid>
              <Grid
                container
                columnSpacing={2}
                sx={styles.gridUbicacionIglesia}
              >
                <Grid item xs={12}>
                  <Typography
                    variant={RegisterFormEnum.SUBTITLE1}
                    sx={styles.typography7}
                  >
                    {RegisterFormEnum.DIRECCION_IGLESIA}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.COUNTRY}
                    label={Locaciones.PAIS}
                    options={countries ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    onChange={(key, value) =>
                      handleCountryChange(key, value, IGLESIA)
                    }
                    size={Sizes.SMALL}
                    loadState={loadState}
                    errors={false}
                    value={null}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.PROVINCE}
                    label={Locaciones.PROVINCIA}
                    loadState={loadState}
                    options={iglesiaProvinces ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    onChange={(key, value) =>
                      handleProvinceChange(key, value, IGLESIA)
                    }
                    size={Sizes.SMALL}
                    errors={false}
                    value={null}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.PART}
                    label={Locaciones.PARTIDO}
                    options={iglesiaParts ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    loadState={loadState}
                    onChange={(key, value) =>
                      handlePartChange(key, value, IGLESIA)
                    }
                    size={Sizes.SMALL}
                    errors={false}
                    value={null}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={IGLESIA}
                    label={IGLESIA_CAPITALIZED}
                    options={iglesias ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    loadState={loadState}
                    onChange={(key: any, value: any) => {
                      setIglesia(value);
                    }}
                    size={Sizes.SMALL}
                    errors={false}
                    helperText={""}
                    value={null}
                  />
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} sx={styles.gridLugarNacimiento}>
                <Grid item xs={12}>
                  <Typography
                    variant={RegisterFormEnum.SUBTITLE1}
                    sx={styles.typography7}
                  >
                    {RegisterFormEnum.LUGAR_NACIMIENTO}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.COUNTRY}
                    label={Locaciones.PAIS}
                    options={countries ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    onChange={(key, value) =>
                      handleCountryChange(key, value, NACIMIENTO)
                    }
                    size={Sizes.SMALL}
                    loadState={loadState}
                    errors={false}
                    value={nacimientoCountry}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.PROVINCE}
                    label={Locaciones.PROVINCIA}
                    loadState={loadState}
                    options={nacimientoProvinces ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    onChange={(key, value) =>
                      handleProvinceChange(key, value, NACIMIENTO)
                    }
                    size={Sizes.SMALL}
                    errors={false}
                    value={nacimientoProvince}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.PART}
                    label={Locaciones.PARTIDO}
                    options={nacimientoParts ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    loadState={loadState}
                    onChange={(key, value) =>
                      handlePartChange(key, value, NACIMIENTO)
                    }
                    size={Sizes.SMALL}
                    errors={false}
                    value={nacimientoPart}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationSelect
                    id={Locations.LOCALITY}
                    label={Locaciones.LOCALIDAD}
                    options={nacimientoLocalities ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    loadState={loadState}
                    onChange={(key: any, value: any) => {
                      setNacimientoLocality(value);
                    }}
                    size={Sizes.SMALL}
                    errors={false}
                    value={nacimientoLocality}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} style={styles.grid3}>
                <Typography
                  variant={RegisterFormEnum.SUBTITLE1}
                  sx={styles.typography6}
                >
                  {RegisterFormEnum.ROL_IGLESIA}
                </Typography>
                <Autocomplete
                  multiple
                  fullWidth
                  options={[
                    UserRoles.SACERDOTE,
                    UserRoles.ADMINISTRATOR,
                    UserRoles.SECRETARIA,
                    UserRoles.COORDINADOR,
                    UserRoles.SACERDOTE_INVITADO
                  ]}
                  onChange={(event, value) => setRolesIglesia(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={RegisterFormEnum.ROL_IGLESIA}
                      placeholder={RegisterFormEnum.SELECCIONE}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid sx={styles.gridTypography}>
              <Typography sx={styles.typographyPais}>
                {Create_Sacerdote.LUGAR_NACIMIENTO_RECOMENDADO_PART_ONE}{" "}
                {paisRecomendado}{" "}
                {Create_Sacerdote.LUGAR_NACIMIENTO_RECOMENDADO_PART_TWO}{" "}
                {ciudadRecomendada}
              </Typography>
            </Grid>
            <Grid sx={styles.alert}>
              {showAlert &&
                (error ? (
                  <Alert style={styles.errorStyle} severity={Status.ERROR}>
                    {errorMessage}
                  </Alert>
                ) : (
                  <Alert style={styles.errorStyleOk} severity={Status.SUCCESS}>
                    {message}
                  </Alert>
                ))}
            </Grid>
            <Grid sx={styles.gridButton}>
              <Button
                onClick={(e) => handleSubmit(e)}
                variant={CONTAINED}
                color={InOrder.PRIMARY}
                type={FormTextSend.SUBMIT}
                sx={styles.button}
                fullWidth
              >
                {Create_Sacerdote.CREAR_SACERDOTE}
              </Button>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default CreateUserSacerdote;
