import { LoadState } from "../../enums/LoadStateEnum";
import { SacramentoEnum } from "../../enums/SacramentoEnum";
import { UserRoles } from "../../enums/components/componentsEnum";
import { RoleIglesia } from "../../interfaces/components/interfacesForms";
import { isSuperAdmin } from "../auth";
import { Option } from "../../interfaces/components/interfacesAutoComplete";
import { validatePassword } from "../../components/passwordValidation";

export const shouldRenderChildForm = (
  childForm: boolean,
  type: SacramentoEnum,
  renderFunction: () => JSX.Element,
) => {
  if (childForm && type !== SacramentoEnum.Comunion) {
    return renderFunction();
  }
  return null;
};

export const handleLocationChange = (
  key: any,
  value: any,
  onChange: (key: any, value: any) => void,
  loadState: { current: LoadState },
  serviceFunction: (id: any) => Promise<any>,
  nacimiento: boolean,
  setBirthLocation: (response: any) => void,
  setLocation: (response: any) => void,
) => {
  if (!value) return;
  if (key == "document") {
    loadState.current = LoadState.DEFAULT;
  }
  onChange(key as any, value);
  serviceFunction(value.id)
    .then((response: any) => {
      if (nacimiento) {
        setBirthLocation(response);
      } else {
        setLocation(response);
      }
    })
    .catch((error: any) => {
      console.log("Error in get location", error);
    });
};

export const assignResponseToFormData = (formData: any, response: any) => {
  Object.assign(formData, response, {
    document: response?.document,
    street: response.address?.street,
    number: response.address?.number,
    nationality: response.nationality,
    birthProvince: response.locality?.part?.province,
    birthPart: response.locality?.part,
    birthLocality: response.locality,
    country: { ...response.address?.locality?.part?.province?.country },
    locality: { ...response.address?.locality },
    part: { ...response.address?.locality?.part },
    province: { ...response.address?.locality?.part?.province },
  });
};

export const getDocumentTypes = () => [
  { id: "DNI", name: "DNI" },
  { id: "SIN_DNI", name: "SIN_DNI" },
  { id: "PASAPORTE", name: "PASAPORTE" },
  { id: "CUIL", name: "CUIL" },
  { id: "CUIT", name: "CUIT" },
  { id: "CDI", name: "CDI" },
  { id: "TI", name: "TI" },
  { id: "LC", name: "LC" },
  { id: "LE", name: "LE" },
  { id: "CI", name: "CI" },
];

export const getMaritalStatuses = () => [
  { id: "Soltero", name: "Soltero" },
  { id: "Viudo", name: "Viudo" },
  { id: "Divorciado", name: "Divorciado" },
];

export const assignSacramentoToFormData = (formData: any, sacramento: any) => {
  Object.assign(formData, { baptism: { ...sacramento } });
  formData.baptism = {
    ...formData.baptism,
    country: { ...sacramento?.iglesia?.locality?.part?.province?.country },
    province: { ...sacramento?.iglesia?.locality?.part?.province },
    part: { ...sacramento?.iglesia?.locality?.part },
    iglesia: { ...sacramento?.iglesia },
  };
};

export function assignFormDataChild(formData: any, response: any) {
  const { document: dni, address, nationality, locality } = response;
  const { street, number } = address || {};
  const { part } = locality || {};
  const { province } = part || {};

  return Object.assign(formData, response, {
    document: dni,
    street: street,
    number: number,
    nationality: nationality,
    birthProvince: part?.province,
    birthPart: part,
    birthLocality: locality,
    country: { ...province?.country },
    locality: { ...address?.locality },
    part: { ...address?.locality?.part },
    province: { ...address?.locality?.part?.province },
  });
}

export const fieldsPersonForm = [
  { name: "apellido", label: "Apellido", type: "text" },
  { name: "nombre", label: "Nombre", type: "text" },
  { name: "dni", label: "DNI", type: "number" },
  { name: "fecha_nac", label: "Fecha de nacimiento", type: "date" },
  { name: "menor", label: "Menor", type: "boolean" },
  { name: "colegio", label: "Colegio", type: "text" },
  { name: "id_madre", label: "ID Madre", type: "number" },
  { name: "id_padre", label: "ID Padre", type: "number" },
  { name: "domicilio_calle", label: "Calle", type: "text" },
  { name: "domicilio_loc", label: "Localidad", type: "text" },
  { name: "domicilio_part", label: "Partido", type: "text" },
  { name: "pais_nacimiento", label: "País de nacimiento", type: "text" },
  { name: "prov_nacimiento", label: "Provincia de nacimiento", type: "text" },
  { name: "loc_nacimiento", label: "Localidad de nacimiento", type: "text" },
  { name: "fe", label: "Fe", type: "text" },
  { name: "domicilio_num", label: "Número de Calle", type: "text" },
];

export function initializeErrors() {
  return {
    email: "",
    nombre: "",
    apellido: "",
    contraseña: "",
    telefono: "",
    fechaNacimiento: "",
    documento: "",
    document_type: "",
    confirmarContraseña: "",
    iglesias: "",
  };
}

export function initializeErrorsUserSacerdote() {
  return {
    email: "",
    nombre: "",
    apellido: "",
    contraseña: "",
    telefono: "",
    fechaNacimiento: "",
    documento: "",
    document_type: "",
    confirmarContraseña: "",
    arquidioseis_incardinado: "",
    iglesias: "",
  };
}

export function initializeNewErrors() {
  return {
    email: "",
    nombre: "",
    apellido: "",
    contraseña: "",
    telefono: "",
    fechaNacimiento: "",
    documento: "",
    confirmarContraseña: "",
    iglesias: "",
  };
}

export function validateFormUtils(
  fullName: string,
  lastName: string,
  password: string,
  confirmPassword: string,
  document: string,
  email: string,
  phone: string,
  birthDate: any
) {
  const newErrors = initializeNewErrors();

  if (!fullName.trim()) {
    newErrors.nombre = "Ingrese su nombre.";
  }

  if (!lastName.trim()) {
    newErrors.apellido = "Ingrese su apellido.";
  }

  if (!password.trim()) {
    newErrors.contraseña = "Ingrese una contraseña";
  } else {
    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      newErrors.contraseña = passwordErrors.join(" ");
    }
  }

  if (!confirmPassword.trim()) {
    newErrors.confirmarContraseña = "Ingrese una contraseña";
  } else if (password !== confirmPassword) {
    newErrors.confirmarContraseña = "Las contraseñas no coinciden.";
  }

  if (!/^\d+$/.test(document)) {
    newErrors.documento = "El campo Documento solo debe contener números.";
  }

  if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors.email = "Ingrese un email válido.";
  }

  if (!/^\d+$/.test(phone)) {
    newErrors.telefono = "El campo Teléfono solo debe contener números.";
  }

  if (!birthDate) {
    newErrors.fechaNacimiento = "Ingrese su fecha de nacimiento.";
  }

  return newErrors;
}

export function createFormStateInRegister(
  email: string,
  fullName: string,
  lastName: string,
  password: string,
  phone: string,
  birthDate: any,
  document: string,
  documentType: string,
  confirmPassword: string,
  isAdmin: boolean,
  authService: any,
  church: any,
  imageUrl: any,
  userType: any,
  rolesIglesia: any,
) {
  return {
    email,
    nombre: fullName,
    apellido: lastName,
    contraseña: password,
    telefono: phone,
    fecha_nacimiento: birthDate,
    documento: document,
    document_type: documentType,
    confirmarContraseña: confirmPassword,
    id_igle: !isSuperAdmin()
      ? authService.getIglesiaSelected()?.iglesia?.id
      : church?.id_igle,
    iglesias: church ? [church] : [],
    imageProfile: imageUrl,
    systemRols: userType ? [userType] : [],
    rolesIglesia: rolesIglesia,
  };
}

export function createFormStateInRegisterForUserFetched(
  doumento: string,
  documentType: string,
  id_igle: number,
  rolesIglesia: any,
) {
  return {
    documento: doumento,
    document_type: documentType,
    id_igle: id_igle,
    rolesIglesia: rolesIglesia,
  };
}

export function createFormStateInRegisterUserSacerdote(
  id: string | undefined,
  email: string,
  fullName: string,
  lastName: string,
  password: string,
  phone: string,
  birthDate: any,
  document: string,
  documentType: string,
  confirmPassword: string,
  arquidiocesisSelect: Option | null,
  authService: any,
  church: any,
  imageUrl: any,
  systemRols: any,
  rolesIglesia: any,
  idExcel: any,
  paisNacimiento: any,
  provinciaNacimiento: any,
  localidadNacimiento: any,
) {
  return {
    idSacerdote: id,
    email,
    nombre: fullName,
    apellido: lastName,
    contraseña: password,
    telefono: phone,
    fecha_nacimiento: birthDate,
    documento: document,
    document_type: documentType,
    confirmarContraseña: confirmPassword,
    arquidiocesisSelect: arquidiocesisSelect,
    id_igle: !isSuperAdmin()
      ? authService.getIglesiaSelected()?.iglesia?.id
      : church?.id,
    //TODO check and remove
    iglesias: church ? [church] : [],
    imageProfile: imageUrl,
    systemRols: systemRols,
    rolesIglesia: rolesIglesia,
    idExcel: idExcel,
    paisNacimiento: paisNacimiento,
    provinciaNacimiento: provinciaNacimiento,
    localidadNacimiento: localidadNacimiento,
  };
}

export function initializeRolesIglesia(): RoleIglesia[] {
  return [
    { id: 1, name: UserRoles.SACERDOTE },
    { id: 2, name: UserRoles.ADMINISTRATOR },
    { id: 3, name: UserRoles.SECRETARIA },
  ];
}

export function initializeRolesIglesiaInCreateSacerdote(): RoleIglesia[] {
  return [
    { id: 1, name: UserRoles.SACERDOTE },
    { id: 2, name: UserRoles.ADMINISTRATOR },
  ];
}
