export const errorStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "15px",
  fontSize: "14px",
  color: "red",
};

export const errorStyleOk: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  fontSize: "14px",
  marginTop: "15px",
  color: "green",
};

export const gridErrorStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "20px",
};

export const stylesDivGeneral: React.CSSProperties = { 
  width: "100vw", 
  height: "100vh", 
  display: "flex", 
  justifyContent: "center", 
  alignItems: "center",
  backgroundColor: "#f8f9fa"
};

export const stylesGridContainer: React.CSSProperties = {
  display: "flex", 
  justifyContent:"center", 
  alignItems: "center",
  backgroundColor: "white", 
  padding: "10%",
  width: "60%",
};

export const stylesFirstTypography: React.CSSProperties = {
 margin: "15px 0", 
 fontWeight: 500, 
 color: "#31876C" 
};
