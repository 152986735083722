import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LocationForm from "./LocationForm";
import { inputLabelStyles, styles } from "./GenericLocationBoxStyles";
import { shouldRenderChildForm } from "../../../utils/forms/formsUtils";
import {
  Checks,
  DataPersonal,
  DatosPersonales,
  Locations,
  NUMERO_CAPITALIZED_OFF,
  Roles,
  Sizes,
  TEXT,
} from "../../../enums/components/componentsEnum";
import { useEffect, useState } from "react";

const GenericLocationBox = ({
  formData,
  onChange,
  errors,
  getValidationColor,
  childForm = false,
  type,
  checkFather,
  checkMother,
  isFetch = false,
  loadState,
  formDataOfChild = null,
  isMother = false,
  isFather = false,
}: any) => {

  // Reset street and number based on the checkFather and checkMother state
  const handleCheckChange = (checkKey: string, isChecked: boolean, street: string, number: string) => {
    if (isChecked) {
      onChange(DataPersonal.STREET, street);
      onChange(DataPersonal.NUMBER, number);
    } else {
      onChange(DataPersonal.STREET, "");
      onChange(DataPersonal.NUMBER, "");
    }
  };

  useEffect(() => {
    if (formDataOfChild) {
      // Reset location fields based on Father or Mother selection
      if (formDataOfChild.checkFather) {
        handleCheckChange(Checks.CHECK_FATHER, isFather, formDataOfChild.street, formDataOfChild.number);
        // Set Father location values
        if (isFather) {
          onChange(Locations.COUNTRY, formDataOfChild?.country ?? "");
          onChange(Locations.PROVINCE, formDataOfChild?.province ?? "");
          onChange(Locations.PART, formDataOfChild?.part ?? "");
          onChange(Locations.LOCALITY, formDataOfChild?.locality ?? "");
        }
      } else if (formDataOfChild.checkMother) {
        handleCheckChange(Checks.CHECK_MOTHER, isMother, formDataOfChild.street, formDataOfChild.number);
        // Set Mother location values
        if (isMother) {
          onChange(Locations.COUNTRY, formDataOfChild?.country ?? "");
          onChange(Locations.PROVINCE, formDataOfChild?.province ?? "");
          onChange(Locations.PART, formDataOfChild?.part ?? "");
          onChange(Locations.LOCALITY, formDataOfChild?.locality ?? "");
        }
      } else {
        // If neither Father nor Mother is selected, clear location values
        formData.country = null;
        formData.province = null;
        formData.part = null;
        formData.locality = null;
      }

      // Reset street and number when both father and mother are not selected
      if (!formDataOfChild.checkFather && !formDataOfChild.checkMother) {
        onChange(DataPersonal.STREET, "");
        onChange(DataPersonal.NUMBER, "");
      }

    }
  }, [formDataOfChild, isFather, isMother]);

  return (
    <Box>
      <Paper sx={styles.paper}>
        <Typography sx={styles.typography}>
          {DatosPersonales.DIRECCION}
        </Typography>
        <Grid container spacing={2} sx={styles.gridItem}>
          <LocationForm
            formData={formData}
            onChange={onChange}
            errors={errors}
            nacimiento={false}
            disabled={false}
            isFetch={isFetch}
            loadState={loadState}
          />
          <Grid item xs={6}>
            <TextField
              id={DataPersonal.STREET}
              label={DatosPersonales.CALLE}
              InputLabelProps={{ sx: inputLabelStyles }}
              type={TEXT}
              disabled={false}
              sx={styles.textField(errors, formData, loadState)}
              value={formData.street ?? ""}
              onChange={(e) => onChange(DataPersonal.STREET, e.target.value)}
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.street}
              helperText={errors.street}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={NUMERO_CAPITALIZED_OFF}
              label={DatosPersonales.NUMERO}
              InputLabelProps={{ sx: inputLabelStyles }}
              type={TEXT}
              disabled={false}
              sx={styles.textField(errors, formData, loadState)}
              value={formData.number ?? ""}
              onChange={(e) => onChange(DataPersonal.NUMBER, e.target.value)}
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.number}
              helperText={errors.number}
            />
          </Grid>
          {shouldRenderChildForm(childForm, type, () => (
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(Checks.CHECK_FATHER, e.target.checked);
                      }}
                      checked={formData.checkFather ?? false}
                      value={formData.checkFather ?? false}
                      disabled={checkMother}
                    />
                  }
                  sx={styles.formGroup}
                  label={Roles.PADRE}
                />
              </FormGroup>
            </Grid>
          ))}
          {shouldRenderChildForm(childForm, type, () => (
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(Checks.CHECK_MOTHER, e.target.checked);
                      }}
                      checked={formData.checkMother ?? false}
                      disabled={checkFather}
                      value={formData.checkMother ?? false}
                    />
                  }
                  style={styles.checkBox}
                  label={Roles.MADRE}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default GenericLocationBox;
