export const theme = {
  palette: {
    background: {
      paper: "#FFFFFF",
      default: "#F5F5F5",
    },
  },
  button: {
    primary: "#3A8DBE",
    hover: "#235e7c",
    text: "#fff",
    error: { main: "#f44336", hover: "#ff918f" },
  },
  font: {
    primary: "#000",
  },
  navbar: {
    background: "#3A8DBE",
  },
  alert: {
    success: "#31876C",
    error: "#f44336",
  },
  social: {
    facebook: "#1877F2",
    instagram: "#E4405F",
    whatsapp: "#25D366",
  },
};
