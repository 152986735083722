import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Grid,
  Box,
  IconButton,
  Alert,
} from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import { useNavigate } from "react-router-dom";
import iglesiasService from "../../services/iglesias";
import authService from "../../services/auth";
import administratorService from "../../services/administrator";
import CancelIcon from "@mui/icons-material/Cancel";
import Avatar from "react-avatar";
import { errorStyle, errorStyleOk, gridErrorStyle } from "./ProfilePageStyles";
import { queryClient } from "../../App";
import { addNotification } from "../../utils/notifications";
import EditRolIglesiaOfUser from "../../components/editRolIglesiaOfUser/EditRolIglesiaOfUser";
import usersService from "../../services/users/UsersService";
import { getDecodedToken } from "../../utils/auth";

moment.locale("es");

const ProfilePage = ({
  custom = {},
  isAdmin = false,
  isOwner = false,
  isOnlyForChangeIglesia = false,
}: any) => {
  const [user, setUser] = useState<any>({});
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [idUser, setIdUser] = useState(Number);
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({
    email: "",
    nombre: "",
    apellido: "",
    contraseña: "",
    telefono: "",
    fechaNacimiento: "",
    documento: "",
    confirmarContraseña: "",
    iglesias: "",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [rolesIglesia, setRolesIglesia] = useState<any>([{}]);
  const [iglesiasOfUserFetched, setIglesiasOfUserFetched] = useState<any>([]);
  const [iglesiaIdContext, setIglesiaIdContext] = useState<any>(null);
  const [systemRolsOfUser, setSystemRolsOfUser] = useState<any>([]);
  const [iglesiaSelectedForUser, setIglesiaSelectedForUser] = useState<any>(null);
  const [rolesIglesiasOfUser, setRolesIglesiasOfUser] = useState<any>([]);

  useEffect(() => {
    if (!!custom?.item) {
      administratorService.findById(custom.item.id!).then((response: any) => {
        const {
          nombre,
          apellido,
          documento,
          email,
          telefono,
          fechaNacimiento,
          imageProfile,
          id,
          rolesIglesia,
        } = response;

        setUser(response);
        setIglesiasOfUserFetched(response?.rolesIglesia?.map((rol: any) => rol.iglesia));
        setIglesiaIdContext(authService.getIglesiaSelected()?.iglesia?.id);
        setRolesIglesia(rolesIglesia);
        setFullName(nombre);
        setLastName(apellido);
        setDocument(documento);
        setEmail(email);
        setPhone(telefono);
        setBirthDate(fechaNacimiento);
        setIdUser(id);
        setRolesIglesiasOfUser(rolesIglesia);
        iglesiasService
          .getImage("/auth/get-image/", imageProfile)
          .then((response: any) => {
            try {
              //@ts-ignore
              setImagePreview(URL.createObjectURL(response));
            } catch (error) { }
          })
          .catch((error: any) => { });

        const loadSystemRoles = async () => {
          if (id) {
            const token =getDecodedToken();
            //@ts-ignore
            usersService.getSystemRolsOfUser(token.id).
              then((response: any) => {
                setSystemRolsOfUser(response);
              })
              .catch((error: any) => {
                console.log("error", error);
              });
          }
        };

        loadSystemRoles();
      });
    } else {
      const response = authService.getUserData();
      const {
        nombre,
        apellido,
        documento,
        email,
        telefono,
        fechaNacimiento,
        imageProfile,
        id,
        rolesIglesia,
      } = response;
      setUser(response);
      setIglesiasOfUserFetched(response?.rolesIglesia?.map((rol: any) => rol.iglesia));
      setIglesiaIdContext(authService.getIglesiaSelected()?.iglesia?.id);
      setRolesIglesia(rolesIglesia);
      setFullName(nombre);
      setLastName(apellido);
      setDocument(documento);
      setEmail(email);
      setPhone(telefono);
      setBirthDate(fechaNacimiento);
      setIdUser(id);
      setRolesIglesiasOfUser(rolesIglesia);

      iglesiasService
        .getImage("/auth/get-image/", imageProfile)
        .then((response: any) => {
          try {
            //@ts-ignore
            setImagePreview(URL.createObjectURL(response));
          } catch (error) { }
        });

      const loadSystemRoles = async () => {
        if (id) {
          usersService.getSystemRolsOfUser(id).
            then((response: any) => {
              setSystemRolsOfUser(response);
            })
            .catch((error: any) => {
              console.log("error", error);
            });
        }
      };

      loadSystemRoles();
    }
  }, []);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const { id } = !!custom?.item ? custom?.item : authService.getUserData();
      const response: any = await iglesiasService.uploadImage(
        "/auth/upload-image/" + id,
        file,
      ); // Asegúrate de reemplazar '/your/upload/url' con la URL correcta de tu backend
      if (!!response) {
        try {
          const imageUrl: any = URL.createObjectURL(file);
          setImageUrl(response);
          setImagePreview(imageUrl);
        } catch (error) { }
      }
    }
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (iglesiaSelectedForUser && rolesIglesia.length === 0) {
      addNotification(
        queryClient,
        "error",
        "Debe seleccionar al menos un rol para la iglesia del usuario.",
      );
      return;
    }

    const userData = {
      nombre: fullName,
      apellido: lastName,
      documento: document,
      email: email,
      telefono: phone,
      fechaNacimiento: birthDate,
      imageProfile: imageUrl,
      id_igle: iglesiaIdContext,
      rolesIglesia,
      id_igle_of_user: iglesiaSelectedForUser,
    };

    try {
      const response = await administratorService
        .editUser(idUser, userData)
        .then((result: any) => {
          addNotification(
            queryClient,
            "success",
            "User " +
            result.nombre +
            " " +
            result.apellido +
            " actualizado correctamente.",
          );
          if (custom?.handleClose) {
            custom.handleClose();
          }
          setTimeout(() => {
            window.location.reload();
          }, 250);

        })
        .catch((error: any) => {
          addNotification(
            queryClient,
            "error",
            "No se pudo actualizar el sector.  Error: " + error,
          );
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRolesIglesiaChange = (newRoles: any) => {
    setRolesIglesia(newRoles);
  };

  const handleSelectedIglesiaChange = (iglesia: any) => {
    setIglesiaSelectedForUser(iglesia?.id);
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: !!custom?.backgroundColor
          ? custom?.backgroundColor
          : "#f2f2f2",
        height: "100%",
      }}
    >
      <Box sx={{ width: !!custom.fullScreen ? custom.fullScreen : "50%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <IconButton
            onClick={
              !!custom?.handleGoBack ? custom.handleGoBack : handleGoBack
            }
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "5%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <form onSubmit={handleSubmit} style={{ marginTop: "0px" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ margin: "15px 0", fontWeight: 500, color: "#31876C" }}
                >
                  Perfil
                </Typography>
              </div>
              <div
                style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      paddingLeft: "50px",
                      margin: "15px 0",
                      fontWeight: 500,
                      color: "#31876C",
                    }}
                  >
                    Iglesias
                  </Typography>
                  <ul>
                    {rolesIglesiasOfUser?.map((rol: any) => (
                      <li key={rol?.iglesia?.denomination}>
                        <a
                          href="#"
                          style={{
                            textDecoration: "none",
                            fontSize: 15,
                            fontWeight: 400,
                            color: "grey",
                          }}
                          onClick={() => {
                            authService.selectIglesia(rol);
                          }}
                        >
                          {rol?.iglesia?.denomination}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <Grid
              container
              spacing={2}
              sx={{ "& > .MuiGrid-item": { paddingTop: 0 } }}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "5%",
                  }}
                >
                  {!!imagePreview && (
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        borderRadius: "75px",
                        height: "150px",
                        width: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${imagePreview})`,
                      }}
                    >
                      <input
                        type="file"
                        hidden
                        disabled={false}
                        onChange={handleFileChange}
                      />
                    </Button>
                  )}
                  {!imagePreview && (
                    <label style={{ cursor: "pointer" }}>
                      <Avatar
                        size="150"
                        round={true}
                        name={fullName + " " + lastName}
                      />
                      <input type="file" hidden onChange={handleFileChange} />
                    </label>
                  )}
                </div>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ margin: "15px 0", fontWeight: 500, color: "#31876C" }}
                  >
                    Datos personales
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="full-name"
                  label="Nombre"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  margin="normal"
                  disabled={isOnlyForChangeIglesia}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.nombre}
                  helperText={errors.nombre}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="last-name"
                  label="Apellido"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  margin="normal"
                  disabled={isOnlyForChangeIglesia}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.apellido}
                  helperText={errors.apellido}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="document"
                  label="Documento"
                  type="number"
                  disabled={isOnlyForChangeIglesia}
                  value={document}
                  onChange={(e) => setDocument(e.target.value)}
                  margin="normal"
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.documento}
                  helperText={errors.documento}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  label="Email"
                  type="text"
                  autoComplete="off"
                  value={email}
                  disabled={isOnlyForChangeIglesia}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  size="small"
                  fullWidth
                  error={!!errors.email}
                  helperText={""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="birth-date"
                  label="Fecha de Nacimiento"
                  type="date"
                  disabled={isOnlyForChangeIglesia}
                  autoComplete="off"
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  margin="normal"
                  size="small"
                  fullWidth
                  error={!!errors.fechaNacimiento}
                  helperText={errors.fechaNacimiento}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  label="Teléfono"
                  type="number"
                  autoComplete="off"
                  value={phone}
                  disabled={isOnlyForChangeIglesia}
                  onChange={(e) => setPhone(e.target.value)}
                  margin="normal"
                  size="small"
                  fullWidth
                  error={!!errors.telefono}
                  helperText={errors.telefono}
                />
              </Grid>
              <Grid item xs={12}>
                <EditRolIglesiaOfUser
                  iglesiasOptions={iglesiasOfUserFetched}
                  user={user}
                  idIgle={iglesiaIdContext}
                  systemRolsOfUser={systemRolsOfUser}
                  onRolesIglesiaChange={handleRolesIglesiaChange}
                  onChangeSelectedIglesia={handleSelectedIglesiaChange}
                  isOnlyForChangeIglesia={isOnlyForChangeIglesia}
                />
              </Grid>
              {(isAdmin || isOwner) && (
                <Grid item xs={12}>
                  <Button
                    type="button"
                    onClick={() => {
                      administratorService
                        .requestPasswordReset(user.id, email)
                        .then((response: any) => {
                          setMessage(response);
                          setShowAlert(true);
                          setTimeout(() => setShowAlert(false), 10000);
                        })
                        .catch((error: any) => {
                          setError(
                            "Error al solicitar el cambio de contraseña",
                          );
                          setShowAlert(true);
                          setTimeout(() => setShowAlert(false), 10000);
                        });
                    }}
                    variant="outlined"
                    sx={{
                      marginTop: "18px",
                      backgroundColor: "white",
                      color: "grey",
                    }}
                    fullWidth
                    disabled={isOnlyForChangeIglesia}
                  >
                    Cambiar contraseña
                  </Button>
                </Grid>
              )}
              <Grid style={gridErrorStyle}>
                {showAlert &&
                  (error ? (
                    <Alert style={errorStyle} severity="error">
                      {error}
                    </Alert>
                  ) : (
                    <Alert style={errorStyleOk} severity="success">
                      {message}
                    </Alert>
                  ))}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginTop: "10px", marginBottom: "3%" }}
              fullWidth
              disabled = {isOnlyForChangeIglesia}
            >
              Editar
            </Button>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default ProfilePage;
