import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class RolIglesiaService extends GenericService {
  constructor() {
    super();
  }

  async findRolesIglesiaOfUserByIdWithPagination(
    id: number,
    page: number,
    pageSize: number,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/roliglesia/findRolesIglesiaOfUserByIdUserWithPagination/${id}/${page}/${pageSize}`,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener los roles de la iglesia del usuario");
      return [];
    }
  }
}


const rolIglesiaService = new RolIglesiaService();

export default rolIglesiaService;
