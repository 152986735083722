export enum CreateTurnoEnum {
  CREAR_TURNO = "Crear Turno",
  SELECCIONE_TIPO = "Seleccione el tipo",
  CRITERIO_FERIADOS = "Criterio para los feriados",
  HORARIOS_ESPECIALES = "Horarios especiales",
  IGUAL_DIAS_HABILES = "Igual que los días hábiles",
  SIN_ACTIVIDAD = "Sin actividad",
  ACTUALIZAR_TURNO = "Actualizar Turno",
  CATEDRAL_DE_LA_PLATA = "Catedral de La Plata",
  TURNO_SUCESS = "Se programó el turno correctamente.",
  TURNO_ERROR = "No se pudo crear el turno.",
  TURNO_ACTUALIZADO = "Turno actualizado correctamente.",
  TURNO_NO_ACTUALIZADO = "No se pudo actualizar el turno.",
  BACK = "Volver",
  SELECCIONAR_UNO_O_MAS_DIAS = "Seleccionar uno ó mas días de la semana para aplicar horario",
  SELECCIONAR_DIAS_SEMANAS = "Seleccionar días y semana para aplicar horario",
  SELECCIONAR_DIAS = "Seleccionar días para aplicar horario",
  HORA_INICIO = "Hora de inicio",
  HORA_FIN = "Hora de fin",
  SECTOR = "Sector",
  SACERDOTE = "Sacerdote",
  COORDINADOR = "Coordinador",
  RANGO_FECHAS = "Rango de fechas",
  FECHA_DESDE = "Fecha desde",
  FECHA_HASTA = "Fecha hasta",
  SUCCESS = "success",
  ERROR = "error",
  ERROR_OBTENER_SECTORES = "Error al obtener los sectores",
  ERROR_OBTENER_SACERDOTES = "Error al obtener los sacerdotes",
  ERROR_OBTENER_COORDINATORS = "Error al obtener los coordinadores",
  ERROR_ACTUALIZAR_TURNO = "Error al actualizar el turno",
  ERROR_CREAR_TURNO = "Error al crear el turno",
  CANTIDAD_CUPOS = "Cantidad de cupos",
  NUMBER = "number",
  DATE = "date",
  TIME = "time",
  SUBMIT = "submit",
  CONTAINED = "contained",
  PRIMARY = "primary",
  ERROR_PERIODICIDAD = "Seleccione la periodicidad",
  ERROR_CRITERIO_FERIADOS = "Seleccione el criterio para los feriados",
  ERROR_HORARIO_ESPECIAL = "Seleccione el horario especial",
}

export enum Periodicidad {
  PERIODICIDAD = "Periodicidad",
  DIARIA = "Diaria",
  DIARA_DESCRIPCION = "Se repite todos los días seleccionados en el rango de fechas",
  MENSUAL = "Mensual",
  MENSUAL_DESCRIPCION = "Se repite el día y semana seleccionada de cada mes",
  POR_FECHA = "Por fecha",
  POR_FECHA_DESCRIPCION = "Se repite único día de cada mes",
}

export enum ListTurnosEnum {
  ERROR_APROBAR_TURNO = "Error al aprobar el turno:",
  CONFIRM_DELETE = "¿Estás seguro que deseas eliminar esta programación?",
  TURNO_ELIMINADO = "Se eliminó el turno correctamente",
}

export enum ValuesEnum {
  HORARIO_ESPECIAL = "horario_especial",
  HORARIO_NORMAL = "horario_normal",
  SIN_ACTIVIDAD = "sin_actividad",
  DIARIA = "diaria",
  MENSUAL = "mensual",
  POR_FECHA = "por_fecha",
  DIA_SELECT_LABEL = "dia-select-label",
  DIA_SELECT = "dia-select",
  SEMANA_SELECT_LABEL = "semana-select-label",
  SEMANA_SELECT = "semana-select",
  CUPOS = "cupos",
  SECTOR_ID_LABEL = "sector-id-label",
  SECTOR_ID = "sectorId",
  SECTOR_LABEL = "sector-label",
  SACERDOTE_LABEL = "sacerdote-label",
  SACERDOTE_ID_LABEL = "sacerdote-id-label",
  SACEDORTE_ID = "sacerdoteId",
  COORDINATOR_ID = "coordinatorId",
  TYPE_SELECT_LABEL = "type-select-label",
  TYPE_SELECT = "type-select",
  DEMO_FORM_CONTROL_LABEL = "demo-form-control-label",
  CRITERY_FERIADOS = "criteryFeriados",
  PERIODICITY = "periodicity",
  NAME_ID = "nameId",
  GROUP_ID = "groupId",
  SCOPE = "scope",
  COORDINATOR_ID_LABEL = "sacerdote-id-label",
  TURNO_ID = "turnoId",
}
