import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DisabledUserModal = ({ open, onClose, onOpenChangeIglesia }: any) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        setLoading(true);
        localStorage.removeItem("access_token");
        localStorage.removeItem("iglesiaSelected");
        navigate("/");
        window.location.reload();
    };

    const handleChangeContext = () => {
        onClose();
        onOpenChangeIglesia();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" color="error">
                    Acceso Restringido
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    No tienes acceso a esta iglesia. Por favor, selecciona otra iglesia o cierra sesión.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleChangeContext} variant="contained" color="primary">
                    Cambiar Iglesia
                </Button>
                <Button onClick={handleLogout} variant="contained" color="error" disabled={loading}>
                    Cerrar Sesión
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisabledUserModal;
